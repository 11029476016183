import Parse from "parse";
import { useSetRecoilState } from "recoil";
import { productStore } from "../atoms/productStore";
import { toast } from "react-toastify";

interface interProductTypes {
    getAllProducts(): Promise<any>;
    addNewProduct(e: object): Promise<any>;
    getCurrentCollection(id: any): Promise<any>;
}

export function useProductAction(): interProductTypes {
    const setProducts = useSetRecoilState(productStore);
    return {
        getAllProducts,
        addNewProduct,
        getCurrentCollection,
    };

    async function getAllProducts(): Promise<any> {
        try {
            const result = await Parse.Cloud.run('getAllProducts');
            setProducts({ products: result });
            return result;
        } catch (e: any) {
            toast.error(e.message);
        }
    }
    
    async function addNewProduct(formProduct: object): Promise<any> {
        try {
            const res = await Parse.Cloud.run('addNewProduct', { 'formProduct' : formProduct });
            if (res === true) {
                toast.success('Good, well-registered product.');
                return;
            }
            toast.error('Error : Please fill in all the fields.');
        } catch (e: any) {
            toast.error(e.message);
        }
    }
    
    async function getCurrentCollection(id: any): Promise<any> {
        try {
            const result = await Parse.Cloud.run('getCurrentCollection', { id });
            return result;
        } catch (e: any) {
            toast.error(e.message);
        }
    }

}