export const Loading = () => {
    return (
        <div className="flex h-screen w-screen fixed top-0 bg-black z-50" >
            <div className="m-auto">
                <div className='animate-bounce ml-10 mb-5'>
                    <img src={require('../assets/ev_light.png')} alt="hello ev" className='w-14 h-14' />
                </div>
                <h3 className='text-white text-center'>Hi, admin 👋</h3>
                <h1 className=' text-white text-center'>We prepare your data </h1>
            </div >
        </div >
    )
}
