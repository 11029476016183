import { useCallback, useLayoutEffect, useMemo } from 'react'
import HeaderTable from '../components/HeaderTable'
import { useNavigate } from 'react-router';
import { useTeamManagement } from '../store/actions/teamManagement';
import { useRecoilValue } from 'recoil';
import { currentTeam } from '../store/atoms/TeamStore';

export const TeamManagement = () => {

    // Const useUsersManagementAction
    const useTeam = useTeamManagement()

    // Use a constant navigator.
    const navigate = useNavigate();

    // Returns all users.
    const getAllProducts = useCallback(async () => {
        return await useTeam.getTeam();
    }, [useTeam]);

    const { team } = useRecoilValue(currentTeam);

    const renderDataInStore = useMemo(() => {
        return team ? team : [];
    }, [team]);

    // Returns a list of all users.
    useLayoutEffect(() => {
        getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


  return (
    <div>
    <HeaderTable
        titlePage='Team Managements'
        description={`Any action you take will have consequences, so be careful.`}
        showInputSearch={false}
        showBtnAction={true}
        titleBtnAction='Add New Employee'
        onPress={() => navigate('/team/management/add/employee')}
    />
    <div className="bg-white">
        <div className="overflow-x-auto border-x border-t">
            <table className="table-auto w-full">
                <thead className="border-b">
                    <tr className="bg-gray-100">
                        <th className="text-left p-4 font-medium">
                            First name
                        </th>
                        <th className="text-left p-4 w-[32rem] font-medium">
                            Last name
                        </th>
                        <th className="text-left p-4 font-medium">
                            post
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {renderDataInStore && renderDataInStore.map((emp: any) =>
                        <tr key={emp.id} className="border-b hover:bg-gray-50">
                            <td className="p-4 self-center">
                                {emp.attributes.firstName}
                            </td>
                            <td className="p-4 self-center">
                                {emp.attributes.lastName}
                            </td>
                            <td className="p-4 self-center">
                            {emp.attributes.post}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    </div>
</div>
  )
}
