/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useLayoutEffect } from 'react'
import HeaderTable from '../components/HeaderTable'
import { useProductAction } from '../store/actions/productAction';
import { useRecoilValue } from 'recoil';
import { productStore } from '../store/atoms/productStore';
import moment from 'moment';
import { useNavigate } from 'react-router';

function ProductManagement() {
    // Const useUsersManagementAction
    const productAction = useProductAction();

    // Use a constant navigator.
    const navigate = useNavigate();

    // Returns all users.
    const getAllProducts = useCallback(async () => {
        return await productAction.getAllProducts();
    }, [productAction]);

    // Returns a list of all users.
    useLayoutEffect(() => {
        getAllProducts();
    }, []);

    // Use the users management store.
    const { products }: any = useRecoilValue(productStore);

    return (
        <div>
            <HeaderTable
                titlePage='Products Managements'
                description={`Any action you take will have consequences, so be careful.`}
                showInputSearch={false}
                showBtnAction={true}
                titleBtnAction='Add New Product'
                onPress={() => navigate('/product/management/add/product')}
            />
            <div className="bg-white">
                <div className="overflow-x-auto border-x border-t">
                    <table className="table-auto w-full">
                        <thead className="border-b">
                            <tr className="bg-gray-100">
                                <th className="text-left p-4 font-medium">
                                    Picture
                                </th>
                                <th className="text-left p-4 font-medium">
                                    Title
                                </th>
                                <th className="text-left p-4 w-[32rem] font-medium">
                                    Details
                                </th>
                                <th className="text-left p-4 font-medium">
                                    CreatedAt
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {products && products.map((product: any) =>
                                <tr key={product.id} className="border-b hover:bg-gray-50">
                                    <td className="p-4 self-center" >
                                        <img src={product.attributes.img1.url()} className=" w-20 h-30 rounded" alt='avatar for user' />
                                    </td>
                                    <td className="p-4 self-center">
                                        {product.attributes.title}
                                        {/* {user.attributes.username} */}
                                    </td>
                                    <td className="p-4 self-center">
                                        <span className="bg-green-100 w-32 text-green-800 text-xs font-semibold mr-2 py-0.5 rounded dark:bg-transparent dark:text-gray-600 px-4 truncate">{product.attributes.details.slice(0, 30)}...</span>

                                    </td>
                                    <td className="p-4 self-center">
                                        {moment(product.createdAt).fromNow()}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ProductManagement