import { atom } from 'recoil';

interface usersManagementType {
    users: any | undefined,
    counts: any | undefined
}

export const usersManagementStore = atom({
    key: 'users-management-store',
    default: {
        users: undefined,
        counts: undefined,
    } as usersManagementType,
});