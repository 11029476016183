/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState } from 'react'
import HeaderTable from '../components/HeaderTable'
import _ from 'lodash';
import { toast } from 'react-toastify';

type Props = {}

const SalesPriceSimulator = (props: Props) => {

  const [total, setTotal] = useState(0);
  const [isSaved, setIsSaved] = useState(true);
  const [totalExpenditure, setTotalExpenditure] = useState(0);
  const [margin, setMargin] = useState(1.24);
  const [numPieces, setNumPieces] = useState(200);
  const [form, setForm]: any = useState([
    {
      id: 0,
      name: 'Local / mois',
      value: 15000,
    },
    {
      id: 1,
      name: 'Salaires /mois',
      value: 84000,
    },
    {
      id: 2,
      name: 'Assurance /mois',
      value: 48000,
    },
    {
      id: 3,
      name: 'Electricity / mois',
      value: 2000,
    },
    {
      id: 4,
      name: 'Eau / mois',
      value: 1000,
    },
    {
      id: 5,
      name: 'Tissus',
      value: 150000,
    },
    {
      id: 6,
      name: 'Mercerie (fils, bobine, outils...)',
      value: 15000,
    },
    {
      id: 7,
      name: 'Etiquettes (etiquettes dos, etiquettes marque...)',
      value: 21000,
    },
    {
      id: 8,
      name: 'Patron de couture',
      value: 0,
    },
    {
      id: 9,
      name: 'Livraison',
      value: 0,
    },
    {
      id: 10,
      name: 'Impression patron',
      value: 0,
    },
    {
      id: 11,
      name: 'Pochettes',
      value: 8000,
    },
  ]);

  function formatCurrency(input: any) {
    // Strip all characters from the input except digits and decimal point
    input = input.replace(/[^0-9\.]/g, '');

    // If there's more than one decimal point, only use the first one
    input = input.replace(/(?!^)-/g, '');
    input = input.replace(/^\./g, '');
    input = input.replace(/\.{2,}/g, '.');
    input = input.replace(/^0+([1-9])/g, '$1');
    input = input.replace(/^0+\./g, '0.');

    return input;
  }

  const getSumAllDataInForm = useCallback(() => {
    return _.sumBy(form, function (o: any) { return o.value; });
  }, [form])

  const generatePriceSimulator = useCallback(() => {
    const cal = getSumAllDataInForm();
    setTotal(Math.round(cal / numPieces));
    setTotalExpenditure(Math.round(cal));
  }, [getSumAllDataInForm, numPieces]);

  const handleOnchangeValue = useCallback((e: any) => {
    e.target.value = formatCurrency(e.target.value);
    const find: any = _.findIndex(form, (obj: any) => obj.id == e.target.name)
    const formUpdate = _.update(form, `${[find]}.value`, (n) => e.target.value == '' ? 0 : parseInt(e.target.value));
    setForm(formUpdate);
    setIsSaved(false);
  }, [form, setIsSaved]);

  const saveAllNewValuesInStorage = useCallback(() => {
    const cal: any = getSumAllDataInForm();
    if (parseInt(cal) < 1) {
      toast.error('You have no data to record.')
      setIsSaved(true);
      return;
    }
    window.localStorage.setItem('newDataSimulationPrice', JSON.stringify(form));
    toast.success('Data is saved in storage')
    setIsSaved(true);
  }, [form, getSumAllDataInForm, setIsSaved]);

  const getAllNewValuesInStorage = useCallback(async () => {
    const dataInStorage: any = window.localStorage.getItem('newDataSimulationPrice');
    if (dataInStorage === null) {
      toast.error('Data storage is empty.')
      return;
    }
    setForm(JSON.parse(dataInStorage));
    toast.success('Successful data recovery.')
  }, []);

  const useFormatter = new Intl.NumberFormat('fr-DZ', {
    style: 'currency',
    currency: 'DZD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  return (
    <div>
      <HeaderTable
        titlePage='Sales price simulator'
        description={`Perform
        a simulation to get a rough estimate of the price of 
        a product.`}
        showInputSearch={false}
        showBtnAction={true}
        titleBtnAction={`Generate the price ${useFormatter.format(total)}`}
        onPress={(e) => generatePriceSimulator()}
      />
      <div className='row justify-end w-full flex'>
        <div className=' sm:mr-7 sm:-mt-10 sm:mb-20'>
          <button onClick={() => getAllNewValuesInStorage()} className={`bg-gray-200 px-4 py-2 self-center rounded-full text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300 mr-3`}>Get</button>
          <button onClick={() => isSaved === false && saveAllNewValuesInStorage()} disabled={isSaved === true} className={`bg-gray-200 px-4 py-2 self-center rounded-full text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300 mr-3 disabled:bg-gray-100 disabled:text-gray-300 disabled:cursor-not-allowed`}>Save</button>
          <button onClick={() => setMargin(1.20)} className={`bg-gray-200 px-4 py-2 self-center rounded-full text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300 ${margin === 1.20 && 'bg-blue-300 hover:bg-blue-500'}`}>+20%</button>
          <button onClick={() => setMargin(1.24)} className={`bg-gray-200 px-4 py-2 self-center rounded-full text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300 ${margin === 1.24 && 'bg-blue-300 hover:bg-blue-500'}`}>+24%</button>
          <button onClick={() => setMargin(1.45)} className={`bg-gray-200 px-4 py-2 self-center rounded-full text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300 ${margin === 1.45 && 'bg-blue-300 hover:bg-blue-500'}`}>+45%</button>
          <button onClick={() => setMargin(1.47)} className={`bg-gray-200 px-4 py-2 self-center rounded-full text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300 ${margin === 1.47 && 'bg-blue-300 hover:bg-blue-500'}`}>+47%</button>
          <button onClick={() => setMargin(1.65)} className={`bg-gray-200 px-4 py-2 self-center rounded-full text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300 ${margin === 1.65 && 'bg-blue-300 hover:bg-blue-500'}`}>+65%</button>
          <button onClick={() => setMargin(1.67)} className={`bg-gray-200 px-4 py-2 self-center rounded-full text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300 ${margin === 1.67 && 'bg-blue-300 hover:bg-blue-500'}`}>+67%</button>
          <button onClick={() => setMargin(1.75)} className={`bg-gray-200 px-4 py-2 self-center rounded-full text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300 ${margin === 1.75 && 'bg-blue-300 hover:bg-blue-500'}`}>+75%</button>
          <button onClick={() => setMargin(1.85)} className={`bg-gray-200 px-4 py-2 self-center rounded-full text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300 ${margin === 1.85 && 'bg-blue-300 hover:bg-blue-500'}`}>+85%</button>
          <button onClick={() => setMargin(1.95)} className={`bg-gray-200 px-4 py-2 self-center rounded-full text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300 ${margin === 1.95 && 'bg-blue-300 hover:bg-blue-500'}`}>+95%</button>
        </div>
      </div>
      <div className="bg-white">
        {total > 0 && <div className=' text-center bg-blue-300 pt-20 pb-10'>
          <div className=' pb-10'>
            <div>
              <span className=' font-sans text-gray-600'>The total amount of production costs : </span>
              <span className=' text-gray-900 text-sm font-bold'> {useFormatter.format(totalExpenditure)}</span>
            </div>
            <div>
              <span className=' font-sans text-gray-600'>MSP (minimum selling price): </span>
              <span className=' text-gray-900 text-sm font-bold bg-white px-2 rounded-2xl py-1'> {useFormatter.format(total)}</span>
            </div>
          </div>
          <div className=' border-b-4 bg-white my-5' />
          <div className='py-10'>
            <div>
              <span className='font-sans text-gray-700'>Turnover of <span className=' font-bold'>{numPieces}</span> pieces with a margin of <span className='font-bold'>{Math.round((margin - 1) * 100)}%</span> : </span>
              <span className=' text-gray-900 text-sm font-bold'> {useFormatter.format(Math.round((total * margin) * numPieces))}</span>
            </div>
            <div>
              <span className='font-sans text-gray-700'>Price for one part with <span className='font-bold'>{Math.round((margin - 1) * 100)}%</span> margin : </span>
              <span className=' text-gray-900 text-sm font-bold bg-white px-2 rounded-2xl py-1'> {useFormatter.format(Math.round(total * margin))}</span>
            </div>
          </div>
          <div className=' border-b-4 bg-white my-5' />
          <div className='py-10'>
            <div>
              <span className=' font-sans text-gray-600'>Total profit generated by the <span className='font-bold'>{numPieces}</span> pieces : </span>
              <span className=' text-gray-900 text-sm font-bold'> {useFormatter.format(Math.round(((total * margin) - total) * numPieces))}</span>
            </div>
            <div>
              <span className=' font-sans text-gray-600'>The profit generated by a piece : </span>
              <span className=' text-gray-900 text-sm font-bold bg-white px-2 rounded-2xl py-1'> {useFormatter.format(Math.round((total * margin) - total))}</span>
            </div>
          </div>
        </div>}
        <div className="overflow-x-auto border-x border-t">
          <table className="table-auto w-full">
            <thead className="border-b">
              <tr className="bg-white">
                <th className="text-left p-4 font-medium">
                  Accrued liabilities
                </th>
                <th className="text-left p-4 w-[32rem] font-medium">
                  Default value
                </th>
                <th className="text-left p-4 font-medium">
                  New value
                </th>
              </tr>
            </thead>
            <tbody>
              {form && form.map((cost: any) =>
                <tr key={cost.id} className={"border-b hover:bg-gray-50"}>
                  <td className="p-4 self-center">
                    {cost.name}
                  </td>
                  <td className="p-4 self-center">
                    {useFormatter.format(cost.value)}
                  </td>
                  <td className="p-4 self-center">
                    <input defaultValue={''} onChange={(e) => handleOnchangeValue(e)} name={cost.id} placeholder='new value' className={'w-20'} /> <span className=' text-sm text-gray-500'>{'DA'}</span>
                  </td>
                </tr>
              )}
              <tr key={'category.id'} className={"border-b  bg-blue-400 hover:bg-blue-500"}>
                <td className="p-4 self-center">
                  Nombre de pièces
                </td>
                <td className="p-4 self-center">
                  {numPieces}<span className=' text-sm text-gray-500'>{' Piece'}</span>
                </td>
                <td className="p-4 self-center">
                  <input onChange={(e: any) => e.target.value < 1 ? setNumPieces(200) : setNumPieces(e.target.value)} placeholder='new value' className={' w-20 bg-blue-400'} /> Piece(s)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default SalesPriceSimulator