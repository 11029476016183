/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useState } from 'react'
import HeaderTable from '../components/HeaderTable'
import { useNavigate } from 'react-router';
import { useCategoryAction } from '../store/actions/CategoryAction';
import { useTeamManagement } from '../store/actions/teamManagement';

function AddNewEmployee() {
  const [loadingData, setLoadingData] = useState(false);
  const [formEmployee, setFormEmployee] = useState({
    FN: '',
    LN: '',
    POST: ''
  });

  // init category action
  const useTeam = useTeamManagement();

  // handle change text in state
  const handleChangeTextInput = useCallback( (e: any) => {
    setFormEmployee({ ...formEmployee , [e.name]: e.value })
  }, [formEmployee]);


  const handleSubmitData = useCallback( async () => {
    setLoadingData(true);
      try {
        await useTeam.newEmployee(formEmployee, setLoadingData);
      } catch (e: any) {
        console.log(e.message);
      }
  }, [formEmployee, setLoadingData, useTeam]);

  // Use a constant navigator.
  const navigate = useNavigate();

  return (
    <div className='w-full grid place-items-center'>
      <HeaderTable
        titlePage='New Employee Managements'
        description={`Any action you take will have consequences, so be careful.`}
        showInputSearch={false}
        showBtnAction={true}
        titleBtnAction='Return to Team List'
        onPress={() => navigate('/team/management')}
      />
      <div>
        <div className=" justify-end flex w-[50rem]">
          <button onClick={() => handleSubmitData()} className="bg-gray-200 px-3 py-1  mb-2 rounded-lg ml-2 text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300">{loadingData ? 'In Progress' : 'Just Save'}</button>
        </div>
        <div className="ml-3 w-[50rem] justify-center">
          <label className=" text-gray-400 text-sm">First Name</label>
          <input required onChange={(e: any) => handleChangeTextInput(e.target)} className="bg-gray-100 p-3 outline-none mb-2 w-full block " type="text" name="FN" id="" placeholder="LN" />
        </div>
        <div className="ml-3 w-[50rem] justify-center">
          <label className=" text-gray-400 text-sm">Last Name</label>
          <input required onChange={(e: any) => handleChangeTextInput(e.target)} className="bg-gray-100 p-3 outline-none mb-2 w-full block " type="text" name="LN" id="" placeholder="FN" />
        </div>
        <div className="ml-3 w-[50rem] justify-center">
          <label className=" text-gray-400 text-sm">Post</label>
          <input required onChange={(e: any) => handleChangeTextInput(e.target)} className="bg-gray-100 p-3 outline-none mb-2 w-full block " type="text" name="POST" id="" placeholder="title post " />
        </div>
      </div>
    </div>
  )
}

export default AddNewEmployee