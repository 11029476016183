import { useCallback, useState } from "react"
import { useNavigate } from "react-router";
import { useUserActions } from "../store/actions/currentUserAction";

export default function Example() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const useUserAction = useUserActions();

    // Use a constant navigator.
    const navigate = useNavigate();


    // Attempts to login with the given email and password.
    const [formLogin, setFormLogin]: any = useState({
        username: '',
        password: '',
        loading: false
    });

    // Handle change value inputs.
    const handlechangeValueInputs = useCallback((e: any) => {
        formLogin[e.name] = e.value;
    }, [formLogin]);

    // Handle a submit login
    const handleSubmitLogin = useCallback(async () => {
        try {
            await useUserAction.login(formLogin.username, formLogin.password, navigate, setFormLogin);
        } catch (error: any) {
            console.log(error.message);
        }
    }, [useUserAction, formLogin, navigate]);

    return (
        <>
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-md space-y-8">
                    <div>
                        <img src={require('../assets/ev_black.png')} alt="hello ev" className='mx-auto h-20 w-auto' />
                        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                            Sign in to your account
                        </h2>
                        <p className="mt-2 text-center text-sm text-indigo-600 hover:text-indigo-500">
                            Management of Evaxio
                        </p>
                    </div>
                    <input type="hidden" name="remember" defaultValue="true" />
                    <div className="-space-y-px rounded-md shadow-sm">
                        <div>
                            <label htmlFor="email-address" className="sr-only">
                                Email address
                            </label>
                            <input
                                id="email-address"
                                name="username"
                                type="username"
                                onChange={(e: any) => handlechangeValueInputs(e.target)}
                                required
                                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                placeholder="Email address"
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">
                                Password
                            </label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                onChange={(e: any) => handlechangeValueInputs(e.target)}
                                autoComplete="current-password"
                                required
                                className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                placeholder="Password"
                            />
                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <label htmlFor="remember-me" className="ml-2 w-[15rem] block text-sm text-gray-400">
                                By logging in, you agree to the terms and conditions
                            </label>
                        </div>
                    </div>

                    <div>
                        <button
                            onClick={handleSubmitLogin}
                            className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">                </span>
                            {formLogin.loading === true ? 'Loading ... ' : 'Sign in'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
