import { User } from 'parse';
import { atom } from 'recoil';

interface currentUserType {
    isAuth: boolean | undefined,
    uuid: string | undefined,
    attrs: User<any> | undefined | null
}

export const currentUser = atom({
    key: 'current-user-store',
    default: {
        isAuth: undefined,
        uuid: undefined,
        attrs: undefined,
    } as currentUserType,
});