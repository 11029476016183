/* eslint-disable array-callback-return */
/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo, useState, Fragment, useCallback, useEffect, memo } from 'react';
import HeaderTable from '../components/HeaderTable'
import { useNavigate, useParams } from 'react-router';
import { Listbox, Transition } from '@headlessui/react'
import { useProductAction } from '../store/actions/productAction';
import { useCategoryAction } from '../store/actions/CategoryAction';
import { useCollectionAction } from '../store/actions/collectionAction';

function classNames(...classes: any): any {
  return classes.filter(Boolean).join(' ')
}

const UpdateCollection = memo((props: any) => {

  // Const useUsersManagementAction
  const productAction = useProductAction();

  // Const useUsersManagementAction
  const collectionAction = useCollectionAction();

  // Const useUsersManagementAction
  const categoryAction = useCategoryAction();

  //const useQuery
  const { id } = useParams();

  if (id === undefined) throw new Error('Something went wrong');

  // Creates a formulary.
  const formulary = useMemo(() => {
    return ({
      collectionId: null,
      cname: {
        isNewName: false,
        name: undefined
      },
      price: undefined,
      product: undefined,
      category: undefined,
      sizes: [
        {
          size: 'XS',
          qty: 0,
          additional: 0
        },
        {
          size: 'S',
          qty: 0,
          additional: 0
        },
        {
          size: 'M',
          qty: 0,
          additional: 0
        },
        {
          size: 'L',
          qty: 0,
          additional: 0
        },
        {
          size: 'XL',
          qty: 0,
          additional: 0
        },
        {
          size: 'XXL',
          qty: 0,
          additional: 0
        },
        {
          size: '2XL',
          qty: 0,
          additional: 0
        },
        {
          size: '3XL',
          qty: 0,
          additional: 0
        },
      ],
    })
  }, []);

  const [currentCollection, setCurrentCollection]: any = useState(null);

  const [products, setProducts]: any = useState(null)
  const [selectedProduct, setSelectedProduct]: any = useState(null)

  const [categorys, setCategorys]: any = useState(null)
  const [selectedCategory, setSelectedCategory]: any = useState(null)

  const [formCollection, setFormCollection]: any = useState(formulary);

  const [collectionName, setCollectionName]: any = useState(null);
  const [selectedCollectionName, setSelectedCollectionName]: any = useState(null);

  // Use a constant navigator.
  const navigate = useNavigate();

  // Returns all current collection.
  const getCurrentCollection = useCallback(async () => {
    let res = await productAction.getCurrentCollection(id);
    setFormCollection({ ...formCollection, price: res.result[0].attributes.price });
    setSelectedCategory(res.result[0].attributes.category);
    setSelectedCollectionName(res.result[0].attributes.cname);
    // set data  collection  in form collection
    formCollection.sizes.map((el: any, idx: any) => {
      formCollection.sizes[idx].qty = res.csResult[idx].attributes.quantity;
      formCollection.sizes[idx].additional = res.csResult[idx].attributes.additional;
    });
    setSelectedProduct(res.result[0].attributes.product);
    setCurrentCollection(res);
    return;
  }, [currentCollection]);

  // Returns all products.
  const getAllProducts = useCallback(async () => {
    const res = await productAction.getAllProducts();
    setSelectedProduct(res[0]);
    setProducts(res);
    return;
  }, [selectedProduct]);

  // Returns all products.
  const getAllCategorys = useCallback(async () => {
    const resu = await categoryAction.getAllCategory();
    setCategorys(resu);
    return;
  }, [currentCollection, selectedCategory]);

  // Returns all products.
  const getAllCollectionName = useCallback(async () => {
    const result = await collectionAction.getAllCollectionName();
    setCollectionName(result);
    setSelectedCollectionName(result[0]);
    return;
  }, [collectionName]);

  // Handle change text input.
  const handleChangeTextInput = useCallback((e: any) => {
    if (e.name === 'price') {
      formCollection[e.name] = parseInt(e.value);
      return;
    }
    if (e.name === 'cname') {
      formCollection[e.name].name = e.value;
      return;
    }
    formCollection[e.name] = e.value;
  }, [formCollection]);

  // Handle change text input.
  const handleChangeTextInputSizesQty = useCallback((ev: any) => {
    const filtForm = formCollection.sizes.findIndex((el: any) => el.size === ev.target.name);
    formCollection.sizes[filtForm].qty = parseInt(ev.target.value) > 0 ? parseInt(ev.target.value) : 0;
  }, [formCollection]);

  // Handle change text input.
  const handleChangeTextInputSizesAdditional = useCallback(async (e: any) => {
    const filtForm = formCollection.sizes.findIndex((el: any) => el.size === e.name);
    formCollection.sizes[filtForm].additional = parseInt(e.value) > 0 ? parseInt(e.value) : 0;
  }, [formCollection]);

  // Handle change text input.
  const handleSubmitFormCollection = useCallback(async (e: any) => {
    formCollection.category = selectedCategory;
    formCollection.product = selectedProduct;
    formCollection.collectionId = id;
    // if is new name false
    if (formCollection.cname.isNewName === false) {
      formCollection.cname.name = selectedCollectionName;
    }
    try {
      await collectionAction.updateCollection(formCollection);
    } catch (e: any) {
      console.log(e.message);
    }
  }, [formCollection, selectedProduct, selectedCategory, id, selectedCollectionName]);

  // handle change checkbox of cname
  const handleChangeCheckBoxCname = useCallback((target: any) => {
    setFormCollection({ ...formCollection, cname: { isNewName: target.checked, name: selectedCollectionName.objectId } })
  }, [formCollection]);

  // Returns a list of all products.
  useEffect(() => {
    getAllProducts();
    getAllCategorys();
    getAllCollectionName();
    getCurrentCollection();
  }, []);

  const _renderSizesInput = useMemo(() => {
    return currentCollection !== null && currentCollection.csResult.map((el: any) => (
      <div className="mx-3  w-[40rem] items-end" key={Math.random()}>
        <div className='flex row justify-between w-full'>
          <div>
            <label className=" text-gray-400 text-sm">QUANTITY OF {el.attributes.size.attributes.name} </label>
            <input required onChange={handleChangeTextInputSizesQty} className="bg-gray-100 p-3 outline-none mb-2 w-full block" defaultValue={el.attributes.quantity} type="text" name={el.attributes.size.attributes.name} id="" placeholder="qty" />
          </div>
          <div>
            <label className=" text-gray-400 text-sm">ADDITIONAL PRICE FOR {el.attributes.size.attributes.name} </label>
            <input required onChange={(e: any) => handleChangeTextInputSizesAdditional(e.target)} className="bg-gray-100 p-3 outline-none mb-2 w-full block" defaultValue={el.attributes.additional} type="text" name={el.attributes.size.attributes.name} id="" placeholder="additional price" />
          </div>
        </div>
      </div>
    ))
  }, [currentCollection]);

  return (
    <div>
      <div className="w-full grid h-screen place-items-center">
        <HeaderTable
          titlePage='Update Managements'
          description={`Any action you take will have consequences, so be careful.`}
          showInputSearch={false}
          showBtnAction={true}
          titleBtnAction='Return To Collection'
          onPress={() => navigate('/collection/management')}
        />
        <div className=" justify-end flex w-[50rem]">
          <button onClick={handleSubmitFormCollection} className=" disabled:text-white disabled:hover:bg-gray-200 disabled:cursor-not-allowed bg-blue-200 px-3 py-1  rounded-lg right-0 text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300 -mr-3 mb-5">Update collection</button>
        </div>
        <div className=' bg-blue-50 py-5 px-2 rounded-lg'>
          <div className="ml-3 w-[50rem] justify-center">
            <fieldset className="my-5">
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    onChange={(e: any) => handleChangeCheckBoxCname(e.target)}
                    id="isNewName"
                    name="isNewName"
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="comments" className="font-medium text-gray-700">
                    Create new name for this collection
                  </label>
                </div>
              </div>
            </fieldset>
          </div>
          {formCollection.cname.isNewName === true && <div className=" ml-2 w-[50rem] justify-center">
            <label className=" text-gray-400 text-sm">Collection name</label>
            <input required onChange={(e: any) => handleChangeTextInput(e.target)} className="bg-white p-3 outline-none mb-2 w-full block " type="text" name="cname" id="" placeholder="Nova" />
          </div>}
          {(formCollection.cname.isNewName === false && selectedCollectionName) && <Listbox value={selectedCollectionName} onChange={setSelectedCollectionName} key={Math.random()}>
            {({ open }) => (
              <div className='w-[50rem] ml-2 '>
                <Listbox.Label className="block text-sm text-gray-400 ">Assigned to collection name</Listbox.Label>
                <div className="mt-1 relative">
                  <Listbox.Button className="relative w-full bg-white  p-3 text-left cursor-default focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <span className="flex items-center">
                      <span className="ml-3 block truncate">{selectedCollectionName.attributes.name}</span>
                    </span>
                    <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                      {collectionName && collectionName.map((cname: any) => (
                        <Listbox.Option
                          key={cname.id}
                          className={({ active }) =>
                            classNames(
                              active ? 'text-white bg-indigo-600' : 'text-gray-900',
                              'cursor-default select-none relative py-2 pl-3 pr-9'
                            )
                          }
                          value={cname}
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center">
                                <span
                                  className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                >
                                  {cname.attributes.name}
                                </span>
                              </div>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </div>
            )}
          </Listbox>}
        </div>
        <div className="ml-3 w-[50rem] justify-center my-2">
          <label className=" text-gray-400 text-sm">Collection Price (without additional price) </label>
          <input required onChange={(e: any) => handleChangeTextInput(e.target)} defaultValue={formCollection.price} className="bg-gray-100 p-3 outline-none mb-2 w-full block " type="text" name="price" id="" placeholder="collection price" />
        </div>
        <div className="ml-3  w-[50rem] my-2">
          {selectedProduct && <Listbox value={selectedProduct} onChange={setSelectedProduct} key={Math.random()}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-sm text-gray-400 ">Assigned to product</Listbox.Label>
                <div className="mt-1 relative">
                  <Listbox.Button className="relative w-full bg-gray-100  pl-3 pr-10 py-3 text-left cursor-default focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <span className="flex items-center">
                      <img src={selectedProduct.attributes.img1.url()} alt="" className="flex-shrink-0 h-10 w-8" />
                      <span className="ml-3 block truncate">{selectedProduct.attributes.title}</span>
                    </span>
                    <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                      {products && products.map((product: any) => (
                        <Listbox.Option
                          key={product.id}
                          className={({ active }) =>
                            classNames(
                              active ? 'text-white bg-indigo-600' : 'text-gray-900',
                              'cursor-default select-none relative py-2 pl-3 pr-9'
                            )
                          }
                          value={product}
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center">
                                <img src={product.attributes.img1.url()} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                                <span
                                  className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                >
                                  {product.attributes.title}
                                </span>
                              </div>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>}
        </div>

        <div className="ml-3  w-[50rem] my-2">
          {selectedCategory && <Listbox value={selectedCategory} onChange={setSelectedCategory} key={Math.random()}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-sm text-gray-400 ">Assigned to category</Listbox.Label>
                <div className="mt-1 relative">
                  <Listbox.Button className="relative w-full bg-gray-100  pl-3 pr-10 py-3 text-left cursor-default focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <span className="flex items-center">
                      <span className="ml-3 block truncate">{selectedCategory.attributes.name}</span>
                    </span>
                    <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                      {categorys && categorys.map((category: any) => (
                        <Listbox.Option
                          key={category.id}
                          className={({ active }) =>
                            classNames(
                              active ? 'text-white bg-indigo-600' : 'text-gray-900',
                              'cursor-default select-none relative py-2 pl-3 pr-9'
                            )
                          }
                          value={category}
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center">
                                <span
                                  className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                >
                                  {category.attributes.name}
                                </span>
                              </div>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>}
        </div>
        <div className='mt-10'>
          {_renderSizesInput}
        </div>
      </div>
    </div >
  )
});
export default UpdateCollection