/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import HeaderTable from '../components/HeaderTable'
import { useNavigate } from 'react-router';
import { useTeamManagement } from '../store/actions/teamManagement';
import { useRecoilValue } from 'recoil';
import { currentTeam } from '../store/atoms/TeamStore';

function AddNewDelays() {
  const [loadingData, setLoadingData] = useState(false);
  const [formDelay, setFormDelay] = useState({
    DMIN: 0,
    EMP: '',
    INFO: ''
  });

  // init category action
  const useTeam = useTeamManagement();

  // handle change text in state
  const handleChangeTextInput = useCallback((e: any) => {
    setFormDelay({ ...formDelay, [e.name]: e.value })
  }, [formDelay]);

  const getAllProducts = useCallback(async () => {
    return await useTeam.getTeam();
  }, [useTeam]);

  const { team } = useRecoilValue(currentTeam);

  const renderDataInStore = useMemo(() => {
    return team ? team : [];
  }, [team]);

  // Returns a list of all users.
  useLayoutEffect(() => {
    getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitData = useCallback(async () => {
    setLoadingData(true);
    try {
      await useTeam.setNewDelays(formDelay, setLoadingData);
    } catch (e: any) {
      console.log(e.message);
    }
  }, [formDelay, setLoadingData, useTeam]);

  // Use a constant navigator.
  const navigate = useNavigate();

  return (
    <div className='w-full grid place-items-center'>
      <HeaderTable
        titlePage='Add New Delay'
        description={`Any action you take will have consequences, so be careful.`}
        showInputSearch={false}
        showBtnAction={true}
        titleBtnAction='Return to delays'
        onPress={() => navigate('/team/management/delays/employee')}
      />
      <div>
        <div className=" justify-end flex w-[50rem]">
          <button onClick={() => handleSubmitData()} className="bg-gray-200 px-3 py-1  mb-2 rounded-lg ml-2 text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300">{loadingData ? 'In Progress' : 'Just Save'}</button>
        </div>
        <div className="ml-3 w-[50rem] justify-center">
          <label className=" text-gray-400 text-sm">Delay</label>
          <input required onChange={(e: any) => handleChangeTextInput(e.target)} className="bg-gray-100 p-3 outline-none mb-2 w-full block " type="text" name="DMIN" id="" placeholder="Delay - min" />
        </div>
        <div className="ml-3 w-[50rem] justify-center  my-5">
          <div className="relative min-w-[200px] w-[50rem]">
            <select onChange={(e: any) => handleChangeTextInput(e.target)} name='EMP' className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-gray-500 focus:border-2 focus:border-gray-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50">
              <option>Select user</option>
              {renderDataInStore && renderDataInStore.map((el: any) => (<option value={el.id} key={el.id}>{el.attributes.firstName} {el.attributes.lastName}</option>))}
            </select>
            <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              Select a Employee
            </label>
          </div>
        </div>
        <div className="ml-3 w-[50rem] justify-center">
          <label className=" text-gray-400 text-sm">Info</label>
          <input required onChange={(e: any) => handleChangeTextInput(e.target)} className="bg-gray-100 p-3 outline-none mb-2 w-full block " type="text" name="INFO" id="" placeholder="info of delay" />
        </div>
      </div>
    </div>
  )
}

export default AddNewDelays