import { useSetRecoilState } from "recoil";
import Parse from "parse";
import { currentDelays, currentTeam } from "../atoms/TeamStore";
import { toast } from "react-toastify";

interface interTeamManagement {
    getTeam(): Promise<any>;
    newEmployee(e?: any, s?: any, d?: any): Promise<any>;
    getDelays(): Promise<any>;
    setNewDelays(e?: any, s?: any, d?: any): Promise<any>;
}

export function useTeamManagement(): interTeamManagement {
    const setTeam = useSetRecoilState(currentTeam);
    const setDelays = useSetRecoilState(currentDelays);
    return {
        getTeam,
        newEmployee,
        getDelays,
        setNewDelays
    };

    async function getTeam(): Promise<any> {
        try {
            const result = await Parse.Cloud.run('getAllTeam');
            setTeam({ team: result })
        } catch (e: any) {
            console.log(e.message);
        }
    }

    async function newEmployee(formEmployee: any, setLoadingData: any): Promise<any> {
        try {
            await Parse.Cloud.run('addNewEmployee', { formEmployee });
            toast.success('Good Data is saved');
            setLoadingData(false);
        } catch (e: any) {
            setLoadingData(false);
            toast.error('Problem detected.');
        }
    }

    async function getDelays(): Promise<any> {
        try {
            const result = await Parse.Cloud.run('getAllDelays');
            setDelays({ delays: result });
        } catch (e: any) {
            toast.error('Problem detected.');
        }
    }

    async function setNewDelays(formDelay: any, setLoadingData: any): Promise<any> {
        try {
            await Parse.Cloud.run('setNewDelay', { formDelay });
            setLoadingData(false);
            toast.success('Good Data is saved');
        } catch (e: any) {
            toast.error('Problem detected.');
            setLoadingData(false);
        }
    }


}