/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo, useRef, useState } from 'react'
import HeaderTable from '../components/HeaderTable'
import { useNavigate } from 'react-router';
import { Editor } from '@tinymce/tinymce-react';
import { getBase64 } from '../utils/convertToBase64';
import { useProductAction } from '../store/actions/productAction';
import { toast } from 'react-toastify';

const AddNewProduct = () => {
  // Creates a formulary.
  const formulary = useMemo(() => {
    return ({
      title: '',
      details: '',
      img1: {
        file: '',
        base64: '',
      },
      img2: {
        file: '',
        base64: '',
      },
      img3: {
        file: '',
        base64: '',
      },
    })
  }, []);

  const [formProduct]: any = useState(formulary);
  const [loadingForm, setLoadingForm]: any = useState(false);

  // Use a constant navigator.
  const navigate = useNavigate();

  // Const useUsersManagementAction
  const productAction = useProductAction();

  // Use a constant editorRef.
  const editorRef: any = useRef(null);

  // Handle change text input.
  const handleChangeTextInput = async (e: any) => {
    formProduct[e.name] = e.value;
  }

  // Handle change text input.
  const handleChangeFileInput = useCallback(async (e: any) => {
    formProduct[e.target.name].file = e.target.files[0];
    await getBase64(formProduct[e.target.name].file).then(result => {
      formProduct[e.target.name].base64 = result;
    }).catch(err => {
      console.log(err);
    });
    formProduct[e.target.name].file = e.target.files[0];
  }, [formProduct]);

  // Handle submit and save new product in bdd
  const handleSubmitAndSaveNewProduct = async () => {
    try {
      setLoadingForm(true);
      if (editorRef.current) formProduct.details = editorRef.current.getContent();
      await productAction.addNewProduct(formProduct);
      setLoadingForm(false);
    } catch(e: any) {
      setLoadingForm(false);
    }
  }

  return (
    <div>
      <HeaderTable
        titlePage='Add New Product'
        description={`Any action you take will have consequences, so be careful.`}
        showInputSearch={false}
        showBtnAction={true}
        titleBtnAction='Return To Product'
        onPress={() => navigate('/product/management')}
      />
      <div className="w-full grid h-screen place-items-center">
        <div className=" justify-end flex w-[50rem]">
          <button onClick={handleSubmitAndSaveNewProduct} className=" bg-gray-200 px-3 py-1  mb-2 rounded-lg ml-2 text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300">{loadingForm ? 'Loading..' : 'Just Save'}</button>
        </div>
        <div className="ml-3 w-[50rem] justify-center">
          <label className=" text-gray-400 text-sm">Title of article</label>
          <input required onChange={(e: any) => handleChangeTextInput(e.target)} className="bg-gray-100 p-3 outline-none mb-2 w-full block " type="text" name="title" id="" placeholder="Title" />
        </div>

        <div className="ml-3  w-[50rem]">
          <label className=" text-gray-400 text-sm">image 1 of product</label>
          <input required onChange={handleChangeFileInput} className="bg-yellow-100 p-3 outline-none mb-2 w-full block " type="file" name="img1" id="" placeholder="search..." />
        </div>
        <div className="ml-3  w-[50rem]">
          <label className=" text-gray-400 text-sm">image 2 of product</label>
          <input required onChange={handleChangeFileInput} className="bg-red-100 p-3 outline-none mb-2 w-full block " type="file" name="img2" id="" placeholder="search..." />
        </div>
        <div className="ml-3  w-[50rem]">
          <label className=" text-gray-400 text-sm">image 3 of product</label>
          <input required onChange={handleChangeFileInput} className="bg-green-100 p-3 outline-none mb-2 w-full block " type="file" name="img3" id="" placeholder="search..." />
        </div>
        <div className="mx-3  w-[50rem]">
          <label className=" text-gray-400 text-sm">details of product</label>
          <Editor
            apiKey="5q745td1hp1gy5mgpcvjjyx5tywbhzehk8xmg45frm8n6rvw"
            onInit={(evt, editor) => (editorRef.current = editor)}
            init={{
              height: 600,
              menubar: true,
              plugins:
                "image link table lists autolink searchreplace directionality visualblocks visualchars media  codesample charmap pagebreak nonbreaking anchor insertdatetime autosave wordcount emoticons",
              toolbar:
                "undo redo formatpainter | blocks fontfamily fontsize | bold italic underline forecolor backcolor | link image addcomment showcomments  | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | removeformat",
              toolbar_sticky: true,
              icons: "thin",
              skin: "material-classic",
              content_style: "material-classic",
            }}
          />
        </div>
      </div >
    </div>
  )
}

export default AddNewProduct