export const navigation = [
    { name: 'Dashboard', href: '/' },
    {
        name: 'Articles', href: '/blog/management', subMenu: [
            { name: 'List of Articles', href: '/blog/management' },
            { name: 'Add new article', href: '/blog/management/write/article' },
        ]
    },
    { name: 'Users', href: '/users/management' },
    {
        name: 'Product', href: '/product/management', subMenu: [
            { name: 'List of Products', href: '/product/management' },
            { name: 'Add new Product', href: '/product/management/add/product' },
            { name: 'Sales price simulator', href: '/sales/price/simulator' },
        ]
    },
    { name: 'Condidat', href: '/candidate/management' },
    {
        name: 'Collection', href: '/collection/management', subMenu: [
            { name: 'List of collections', href: '/collection/management' },
            { name: 'Add new collection', href: '/collection/management/add/collection' },
            { name: 'List of collections name', href: '/collectionName/management' },
            { name: 'Add new collection name', href: '/collectionName/management/add/cn' },
        ]
    },
    { name: 'Jobs', href: '/jobs/management' },
    {
        name: 'Category', href: '/category/management', subMenu: [
            { name: 'List of category', href: '/category/management' },
            { name: 'Add new category', href: '/category/management/add/category' },
        ]
    },
    {
        name: 'Team', href: '/team/management', subMenu: [
            { name: 'List of Team', href: '/team/management' },
            { name: 'Add new Employee', href: '/team/management/add/employee' },
            { name: 'List of delays', href: '/team/management/delays/employee' },
            { name: 'Add a delay', href: '/team/management/delays/employee/add' },
        ]
    },
    {
        name: 'Cash register', href: '/cash/management', subMenu: [
            { name: 'Connect to the cash register', href: '/cash/management/connect' },
        ]
    },
];
