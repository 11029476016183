import { atom } from 'recoil';

interface jobsStoreType {
    count: number | undefined,
    jobs: any | undefined,
}

export const jobsStore = atom({
    key: 'jobs-management-store',
    default: {
        count: undefined,
        jobs: undefined,
    } as jobsStoreType,
});