/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useLayoutEffect } from 'react'
import HeaderTable from '../components/HeaderTable'
import { useNavigate } from 'react-router';
import { useCategoryAction } from '../store/actions/CategoryAction';
import { useRecoilValue } from 'recoil';
import { currentCategorys } from '../store/atoms/categoryStore';
import moment from 'moment';

function CategoryManagement() {

    // Const useUsersManagementAction
    const categoryAction: any = useCategoryAction()

    // Use a constant navigator.
    const navigate = useNavigate();

    // Returns all users.
    const getAllProducts = useCallback(async () => {
        return await categoryAction.getAllCategory();
    }, [categoryAction]);

    // Returns a list of all users.
    useLayoutEffect(() => {
        getAllProducts();
    }, []);


    // Use the users management store.
    const { categorys }: any = useRecoilValue(currentCategorys);

    return (
        <div>
            <HeaderTable
                titlePage='Category Managements'
                description={`Any action you take will have consequences, so be careful.`}
                showInputSearch={false}
                showBtnAction={true}
                titleBtnAction='Add New Category'
                onPress={() => navigate('/category/management/add/category')}
            />
            <div className="bg-white">
                <div className="overflow-x-auto border-x border-t">
                    <table className="table-auto w-full">
                        <thead className="border-b">
                            <tr className="bg-gray-100">
                                <th className="text-left p-4 font-medium">
                                    Name Category
                                </th>
                                <th className="text-left p-4 w-[32rem] font-medium">
                                    Details
                                </th>
                                <th className="text-left p-4 font-medium">
                                    CreatedAt
                                </th>
                                <th className="text-left p-4 font-medium">
                                    Update category
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {categorys && categorys.map((category: any) =>
                                <tr key={category.id} className="border-b hover:bg-gray-50">
                                    <td className="p-4 self-center">
                                        {category.attributes.name}
                                    </td>
                                    <td className="p-4 self-center">
                                        {category.attributes.description}
                                    </td>
                                    <td className="p-4 self-center">
                                        {moment(category.createdAt).fromNow()}
                                    </td>
                                    <td className="p-4 self-center">
                                        <button onClick={() => navigate(`/category/management/update/${category.id}`)} className="bg-red-100 text-red-800 text-xs font-semibold mr-2  py-0.5 rounded dark:bg-blue-200 dark:text-blue-900 pl-[11px] pr-[11px] truncate w-28">Update</button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default CategoryManagement