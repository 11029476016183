/* eslint-disable eqeqeq */
import Parse from "parse";
import { useRecoilState, useSetRecoilState } from "recoil";
import { cash_register_card, cash_register_shopping_list, cash_register_sizes_of_product } from "../atoms/cashRegisterStore";
import _ from "lodash";

interface interCashRegisterType {
    getProducts(): Promise<any>;
    getSizeOfProduct(id: any): Promise<any>;
    closeModalOfProduct(): Promise<any>;
    addItemInShoppingList(id: any, sz: any): Promise<any>;
    onRemoveQtyOrItemInShoppingList(itm: any): Promise<any>;
    updateQtyOrDeleteItemInShoppingList(itm: any, nm: any, SISL: any, INPD: any): Promise<any>;
    giveDiscountForItem(itm: any, ind: any): Promise<any>;
    deleteCurrentTicket(): Promise<any>;
}

export function useCashRegister(): interCashRegisterType {
    const _useSetCashRegister = useSetRecoilState(cash_register_card);
    const _useSetSizesPorductCashRegister = useSetRecoilState(cash_register_sizes_of_product);
    const [{ items }, setUseShoppingList] = useRecoilState(cash_register_shopping_list);

    return {
        getProducts,
        getSizeOfProduct,
        closeModalOfProduct,
        addItemInShoppingList,
        onRemoveQtyOrItemInShoppingList,
        updateQtyOrDeleteItemInShoppingList,
        giveDiscountForItem,
        deleteCurrentTicket,
    };

    async function getProducts(): Promise<any> {
        const result = await Parse.Cloud.run('getAllCollection');
        _useSetCashRegister({ products: await result });
    }

    async function getSizeOfProduct(id: any): Promise<any> {
        const result = await Parse.Cloud.run('getSizesOfCollectionById', { id: id });
        _useSetSizesPorductCashRegister({ Sizes: result });
    }

    async function closeModalOfProduct(): Promise<any> {
        _useSetSizesPorductCashRegister({ Sizes: undefined })
    }

    async function addItemInShoppingList(item: any, sz: any): Promise<any> {
        var newItems: any = _.cloneDeep(items);
        var itemWithSize = [item, sz, 1];

        const finderIdx: any = items?.findIndex((o: any) => o[0].id === item.id && o[1].attributes.size.id === sz.attributes.size.id)

        if (finderIdx === -1) {
            setUseShoppingList({ items: [...newItems].concat([itemWithSize]) });
            return;
        }
        const newIdItem = _.update(newItems, `${[finderIdx]}.[2]`, (n) => newItems[finderIdx][2] + 1)
        setUseShoppingList({ items: newIdItem });
    }

    async function onRemoveQtyOrItemInShoppingList(itm: any): Promise<any> {
        var newItems: any = _.cloneDeep(items);

        const finder: any = newItems?.find((o: any) => o[0].id === itm[0].id && o[1].attributes.size.id === itm[1].attributes.size.id)

        const findqty = _.get(finder, '[2]');

        if (findqty > 1) {
            const newIdItem = _.update(finder, `[2]`, (n) => finder['2'] - 1);
            setUseShoppingList({ items: newIdItem });
            console.log(newIdItem);
            return;
        }
    }

    async function updateQtyOrDeleteItemInShoppingList(it: any, nm: any, setSelectedItemInShoppingList: any, setinputDiscount: any): Promise<any> {
        var newItems: any = _.cloneDeep(items);

        const filter = _.filter(newItems, (o: any) => o[0].id === it[0].id && o[1].attributes.size.id === it[1].attributes.size.id);

        const finderIdx: any = _.findIndex(newItems, (o: any) => o[0].id === it[0].id && o[1].attributes.size.id === it[1].attributes.size.id)

        // delete ligne if qty is 0 or -
        if (nm === 0 && filter[0][2] < 2) {
            _.remove(newItems, (o: any) => o[0].id === it[0].id && o[1].attributes.size.id === it[1].attributes.size.id);
            setUseShoppingList({ items: newItems })
            setSelectedItemInShoppingList(null);
            setinputDiscount(null);
            return;
        }

        const newIdItem = nm === 1 ? _.update(newItems, `${[finderIdx]}[2]`, (n) => filter[0][2] + 1) : _.update(newItems, `${[finderIdx]}.[2]`, (n) => filter[0][2] - 1);

        setUseShoppingList({ items: newIdItem })
    }
    
    async function giveDiscountForItem(it: any, inputDiscount: any): Promise<any> {
      //..
    }

    async function deleteCurrentTicket(): Promise<any> {
        setUseShoppingList({ items: [] })
    }
}
