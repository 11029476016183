import React from 'react'

interface interFlexCardType {
    title: string,
    count: number,
    subTitle: string
}

function FlexCard({ title, count, subTitle }: interFlexCardType) {
    return (
        <div>
            <div className="dark:bg-gray-800 shadow-lg hover:cursor-pointer rounded-md flex items-center justify-between p-3 border-b-4 border-blue-600 dark:border-gray-600 text-white font-medium group">
                <div className="flex justify-center items-center w-14 h-14  rounded-full text-xl">
                    {title}
                </div>
                <div className="text-right">
                    <p className="text-2xl">{count}</p>
                    <p className=' font-thin'>{subTitle}</p>
                </div>
            </div>
        </div>
    )
}

export default FlexCard