import { atom } from 'recoil';

interface currentUserType {
    collection: any
}

export const collectionStore = atom({
    key: 'collection-the-store',
    default: {
        collection: undefined,
    } as currentUserType,
});