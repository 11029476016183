/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect } from "react";
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import NoPage from "./pages/NoPage";
import { useRecoilValue } from 'recoil';
import { useUserActions } from "./store/actions/currentUserAction";
import { currentUser } from "./store/atoms/currentUser";
import { Loading } from "./components/Loading";
import ProtectedRoute from "./components/ProtectedRoute";
import { About } from "./pages/About";
import TheNavbar from "./components/the-navbar";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UsersManagement from "./pages/UsersManagement";
import ViewArticle from "./pages/ViewArticle";
import WriteArticle from "./pages/WriteArticle";
import JobManagement from "./pages/ViewJobs";
import AddNewJob from "./pages/AddNewJob";
import CandidateManagement from "./pages/candidateManagement";
import ToolsManagement from "./pages/ToolsManagement";
import AddTools from "./pages/AddTools";
import ProductManagement from "./pages/productManagement";
import AddNewProduct from "./pages/addNewProduct";
import CollectionManagement from "./pages/collectionManagement";
import AddNewCollection from "./pages/addNewCollection";
import UpdateCollection from "./pages/updateCollection";
import CategoryManagement from "./pages/categoryManagement";
import AddNewCategory from "./pages/addNewCategory";
import UpdateCategory from "./pages/updateCategory";
import CollectionNameManagement from "./pages/collectionNameManagement";
import AddNewCollectionName from "./pages/addNewCollectionName";
import UpdateCollectionName from "./pages/updateCollectionName";
import { TeamManagement } from "./pages/teamManagement";
import AddNewEmployee from "./pages/addNewEmployee";
import { DelaysManagement } from "./pages/delaysManagement";
import AddNewDelays from "./pages/addNewDelays";
import SalesPriceSimulator from "./pages/salesPriceSimulator";
import CashRegister from "./pages/cash_register";

function App() {
  // Set useUserActions constant to use user actions.
  const useUserAction = useUserActions();

  // Checks if the current user is an auth user.
  const { isAuth } = useRecoilValue(currentUser);

  useLayoutEffect(() => {
    useUserAction.session();
  }, []);


  return (
    <BrowserRouter>
      {isAuth && <TheNavbar />}
      <Routes>
        <Route path="*" element={<NoPage />} />
        {!isAuth && <Route path="/login" element={<Login />} />}

        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/users/management" element={<UsersManagement />} />
          <Route path="/candidate/management" element={<CandidateManagement />} />
          <Route path="/jobs/management" element={<JobManagement />} />
          <Route path="/tools/management" element={<ToolsManagement />} />
          <Route path="/tools/management/add/tools" element={<AddTools />} />
          <Route path="/jobs/management/add/job" element={<AddNewJob />} />
          <Route path="/blog/management" element={<ViewArticle />} />
          <Route path="/product/management" element={<ProductManagement />} />
          <Route path="/blog/management/write/article" element={<WriteArticle />} />
          <Route path="/collection/management/update/:id" element={<UpdateCollection />} />
          <Route path="/category/management" element={<CategoryManagement />} />
          <Route path="/collectionName/management" element={<CollectionNameManagement />} />
          <Route path="/category/management/add/category" element={<AddNewCategory />} />
          <Route path="/collectionName/management/add/cn" element={<AddNewCollectionName />} />
          <Route path="/category/management/update/:id" element={<UpdateCategory />} />
          <Route path="/collectionName/management/update/:id" element={<UpdateCollectionName />} />
          <Route path="/product/management/add/product" element={<AddNewProduct />} />
          <Route path="/collection/management" element={<CollectionManagement />} />
          <Route path="/team/management" element={<TeamManagement />} />
          <Route path="/team/management/delays/employee" element={<DelaysManagement />} />
          <Route path="/team/management/delays/employee/add" element={<AddNewDelays />} />
          <Route path="/team/management/add/employee" element={<AddNewEmployee />} />
          <Route path="/collection/management/add/collection" element={<AddNewCollection />} />
          <Route path="/sales/price/simulator" element={<SalesPriceSimulator />} />
          <Route path="/cash/management/connect" element={<CashRegister />} />
          <Route path="/about" element={<About />} />
        </Route>

      </Routes>
      {isAuth === undefined && <Loading />}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
}

export default App;
