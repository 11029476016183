import { toast } from "react-toastify";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { usersManagementStore } from "../atoms/usersManagement";
import Parse from "parse";

interface usersType {
    getUsers(value?: any): Promise<any>;
    bannedUserInPlatform(username?: any, setLoadingBanAndUnban?: any): Promise<any>;
    unbannedUserInPlatform(username?: any, setLoadingBanAndUnban?: any): Promise<any>;
    deleteAccountOfUser(username?: any, setLoadingBanAndUnban?: any): Promise<any>;
    getMoreUsers(nmbUsersInStore?: any): Promise<any>;
}

export function useUsersManagementAction(): usersType {
    const setUsersList = useSetRecoilState(usersManagementStore);
    const { users, counts }: any = useRecoilValue(usersManagementStore);
    return {
        getUsers,
        bannedUserInPlatform,
        unbannedUserInPlatform,
        deleteAccountOfUser,
        getMoreUsers
    };

    async function getUsers(value?: any) {
        try {
            const result: any = await Parse.Cloud.run('getAllUsers');
            setUsersList({ users: await result.result, counts: result.countAllUsers });
        } catch (e: any) {
            console.log(e.message)
        }
    }

    async function bannedUserInPlatform(username?: any, setLoadingBanAndUnban?: any) {
        setLoadingBanAndUnban(true);

        // Returns obj if the current user has the same username.
        const find = users.find((el: any) => el.attributes.username === username);

        // Returns idx if the user has the same username as the element.
        const findIndex = users.findIndex((el: any) => el.attributes.username === username);

        // Remove users with the same username.
        const copyStore = users.filter((obj: any) => obj.attributes.username !== username);

        // rebuild object
        const obj = {
            ...find,
            attributes: { ...find.attributes, isBanned: true },
            createdAt: { ...find.createdAt },
            updatedAt: { ...find.updatedAt }
        };

        // Creates a copy of the store.
        const newArr = [...copyStore];

        // add an element in the idx of a new array.
        newArr.splice(findIndex, 0, obj);

        // Sets the users list.
        setUsersList({ users: [...newArr], ...counts })
        try {
            await Parse.Cloud.run('banUser', { username });
            setLoadingBanAndUnban(false);
        } catch (e: any) {
            console.log(e.message);
            setLoadingBanAndUnban(false);
        }
    }

    async function unbannedUserInPlatform(username?: any, setLoadingBanAndUnban?: any) {
        setLoadingBanAndUnban(true);

        // Returns obj if the current user has the same username.
        const find = users.find((el: any) => el.attributes.username === username);

        // Returns idx if the user has the same username as the element.
        const findIndex = users.findIndex((el: any) => el.attributes.username === username);

        // Remove users with the same username.
        const copyStore = users.filter((obj: any) => obj.attributes.username !== username);

        // CreatedAt updatedAt
        const obj = {
            ...find,
            attributes: { ...find.attributes, isBanned: false },
            createdAt: { ...find.createdAt },
            updatedAt: { ...find.updatedAt }
        };

        // Creates a copy of the store.
        const newArr = [...copyStore];

        // add an element in the idx of a new array.
        newArr.splice(findIndex, 0, obj);

        // Sets the users list.
        setUsersList({ users: [...newArr], ...counts })
        try {
            await Parse.Cloud.run('unbanUser', { username });
            setLoadingBanAndUnban(false);
        } catch (e: any) {
            console.log(e.message);
            setLoadingBanAndUnban(false);
        }
    }

    // Delete account of a user.
    async function deleteAccountOfUser(username: any) {
        // Remove users with the same username.
        const arrOriginal = [...users];

        // Remove users with the same username.
        const copyStore = users.filter((obj: any) => obj.attributes.username !== username);

        // Sets the users list.
        setUsersList({ users: [...copyStore], ...counts })

        const result = await Parse.Cloud.run('deleteAccountOfUser', { username });
        try {
            if (result === true) return;
            throw new Error(result);
        } catch (e: any) {
            toast(e.message);
            setUsersList({ users: [...arrOriginal], ...counts })
        }
    }

    // Get more users.
    async function getMoreUsers(nmbUsersInStore: any) {
        try {
            // Get more users.
            const result = await Parse.Cloud.run('getMoreUsers', { nmbUsersInStore });

            // Returns a list of users.
            const newarr = [...users];


            // Removes all users from the store.
            newarr.splice(nmbUsersInStore, 0, ...result.result);

            // Sets the list of users.
            setUsersList({ users: [...newarr], counts })
        } catch (e: any) {
            console.log(e.message);
        }
    }
}