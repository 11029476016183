/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useLayoutEffect, useMemo } from 'react'
import HeaderTable from '../components/HeaderTable'
import { useNavigate } from 'react-router';
import { useTeamManagement } from '../store/actions/teamManagement';
import { useRecoilValue } from 'recoil';
import { currentDelays  } from '../store/atoms/TeamStore';
import moment from 'moment';

export const DelaysManagement = () => {

    // Const useUsersManagementAction
    const useTeam = useTeamManagement()

    // Use a constant navigator.
    const navigate = useNavigate();

    // Returns all users.
    const getAllDelays = useCallback(async () => {
        return await useTeam.getDelays();
    }, [useTeam]);

    const { delays } = useRecoilValue(currentDelays);

    const renderDataInStore = useMemo(() => {
        return delays ? delays : [];
    }, [delays]);

    // Returns a list of all users.
    useLayoutEffect(() => {
        getAllDelays();
     }, []);

    return (
        <div>
            <HeaderTable
                titlePage='Delays Managements'
                description={`Any action you take will have consequences, so be careful.`}
                showInputSearch={false}
                showBtnAction={true}
                titleBtnAction='Add New Delays'
                onPress={() => navigate('/team/management/delays/employee/add')}
            />
            <div className="bg-white">
                <div className="overflow-x-auto border-x border-t">
                    <table className="table-auto w-full">
                        <thead className="border-b">
                            <tr className="bg-gray-100">
                                <th className="text-left p-4 font-medium">
                                    First name
                                </th>
                                <th className="text-left p-4 w-[32rem] font-medium">
                                    Delay in minutes
                                </th>
                                <th className="text-left p-4 font-medium">
                                    Cause
                                </th>
                                <th className="text-left p-4 font-medium">
                                    Request saved
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderDataInStore.length > 0 && renderDataInStore.map((emp: any) =>
                                <tr key={emp.id} className="border-b hover:bg-gray-50">
                                    <td className="p-4 self-center">
                                        {emp.attributes.employee.attributes.firstName} {emp.attributes.employee.attributes.lastName}
                                    </td>
                                    <td className="p-4 self-center">
                                        {emp.attributes.delayMin} min
                                    </td>
                                    <td className="p-4 self-center">
                                        {emp.attributes.info}
                                    </td>
                                    <td className="p-4 self-center">
                                        {moment(emp.createdAt).format()}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
