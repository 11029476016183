import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export default function ModalCondition({ showModal, setShowModal }: any) {
    const cancelButtonRef = useRef(null);
    return (
        <>
            {showModal ? (
                <>
                    <Transition.Root show={showModal} as={Fragment}>
                        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setShowModal}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            <div className="fixed inset-0 z-10 overflow-y-auto">
                                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                                <div className="sm:flex sm:items-start">
                                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                        <div className="mt-2">
                                                            <p className="text-sm text-gray-700">
                                                                <h1 className="font-bold">What is SEO copywriting ?</h1>
                                                                <p className="my-4 text-slate-700 text-md">In short, it is about creating a content optimized in written referencing, that ranks well in the search engine results pages and answers users' questions.</p>
                                                                <h1 className="font-bold">Is SEO important in copywriting?</h1>
                                                                <p className="my-4 text-slate-700 text-md">SEO copywriting is the process of using keyword research and SEO strategies to create web content for users.
                                                                    <br />Search engine crawlers prioritize content that is useful to users, and that's where copywriting comes in.</p>
                                                                <h1 className="font-bold">How to write for SEO</h1>
                                                                <p className="my-4 text-slate-700 text-md">SEO copywriting is not simply writing text and sprinkling it with a few keywords. Since search engines favor content that meets the needs of users, writing "for SEO" and "for users" are essentially the same thing.</p>
                                                                <h1 className=" font-bold">1. Find the right keywords</h1>
                                                                <p className="my-4 text-slate-700 text-md">The first step for any writer is to identify keywords before writing.</p>
                                                                <p className="my-4 text-slate-700 text-md">
                                                                    <a href="https://fr.semrush.com/blog/guide-redaction-seo/?kw=&cmp=FR_SRCH_DSA_Blog_Core_BU_FR&label=dsa_pagefeed&Network=g&Device=c&utm_content=487607528544&kwid=dsa-1100351998244&cmpid=11849486850&agpid=113156852337&BU=Core&extid=23618958704&adpos=&gclid=Cj0KCQiA99ybBhD9ARIsALvZavWZNSeSTcyXFY6iaOLvcgEZaXGbVTsdB3Jn5m7dzKLkfyiARdyHFUUaAnbLEALw_wcB#:~:text=Keyword%20Magic%20Tool%20peut%20vous%20aider%20%C3%A0%20trouver%20des%20mots%20cl%C3%A9s%20accessibles%20dans%20votre%20secteur%20%3A" className="font-bold text-blue-800">Keyword Magic Tool </a>
                                                                    can help you find accessible keywords in your area</p>
                                                                <h1 className="font-bold">2. Find out what questions people are asking</h1>
                                                                <p className="my-4 text-slate-700 text-md">The idea of a search engine is to make finding information as easy as possible. That's why Google continues to develop SERP features; users don't even have to click on a page to get results.</p>
                                                                <p className="my-4 text-slate-700 text-md">Predicting your users' questions is a great way to organize your content.</p>
                                                                <p className="my-4 text-slate-700 text-md">There are several SEO tools that can help you find questions related to your topic and keywords:</p>
                                                                <ul className='mb-3'>
                                                                    <li>
                                                                        <a href="https://alsoasked.com" className=" text-blue-700 font-bold">• AlsoAsked</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="https://answerthepublic.com" className=" text-blue-700 font-bold">• AnswerThePublic</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="https://fr.quora.com" className=" text-blue-700 font-bold">• Quora</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="https://fr.quora.com" className=" text-blue-700 font-bold">• Topic Research</a>
                                                                    </li>
                                                                </ul>
                                                                <h1 className="font-bold">3. Identify and map the research intent</h1>
                                                                <p className="my-4 text-slate-700 text-md">Content and user intent should vary depending on where the user is in their customer journey. Your keywords must therefore also vary according to the different intentions.</p>
                                                                <p className="my-4 text-slate-700 text-md">Finding "keyword intent" simply means identifying why a user enters a particular search term.</p>
                                                                <h1 className="font-bold">4. Review your competitors' articles for your target keywords</h1>
                                                                <p className="my-4 text-slate-700 text-md">
                                                                    Use <span className=" text-blue-700">I Search From</span> or a <span className=" text-blue-700">Chrome Incognito window</span> to see how your competitors are ranking in the SERPs.
                                                                </p>
                                                                <h1 className="font-bold">5. Collect original data</h1>
                                                                <p className="my-4 text-slate-700 text-md">One tactic to engage readers is to share original data and case studies. Not only can this help you generate backlinks, but it can also solidify your company as a leader in your field.</p>
                                                                <h1 className="font-bold">6. Optimize your title, meta title and meta description </h1>
                                                                <p className="my-4 text-slate-700 text-md">
                                                                    And once they're on the page, the title is the first thing they'll see. By making sure your meta and headlines are clear, you can improve your organic click-through rate and increase page views. Note that Google may change your meta on the SERP based on the user's query. However, it is still important to provide a title or description that is optimized for search engines and users.
                                                                </p>
                                                                <div className='py-10'>
                                                                    <img src={require('../../assets/img/original.png')} alt="meta title" title="meta title" />
                                                                    <span className='font-bold my-5'>meta title</span>
                                                                </div>
                                                                <div className='py-10'>
                                                                    <img src={require('../../assets/img/seo.png')} alt="meta description" title="meta description" />
                                                                    <span className='font-bold my-5'>Best SEO 21</span>
                                                                </div>
                                                                <h1 className="font-bold">7. Create organized, easy-to-read content</h1>
                                                                <p className="my-4 text-slate-700 text-md">People use search engines to find specific answers or advice. So they want a quick answer without having to wade through many complex paragraphs.</p>
                                                                <h1 className="font-bold">. A good structure</h1>
                                                                <p className="my-4 text-slate-700 text-md">A good structure guides users and search engines through your content in the most fluid way possible. Each new point should be reflected in the H2, H3 and H4 titles.</p>
                                                                <p className="my-4 text-slate-700 text-md">According to our research, 36% of articles with H2 and H3 tags perform better in terms of traffic, shares and backlinks.</p>
                                                                <h1 className="font-bold">. Titles that are easy to browse</h1>
                                                                <p className="my-4 text-slate-700 text-md">Readers typically skim articles before reading them. According to the Nielsen Norman Group study, only 16% of people will read your entire text.
                                                                    Headlines should inform readers what they will learn by reading individual paragraphs or the page as a whole.
                                                                    Think about the user's intent, provide answers in your headlines and expand on them in the paragraphs that follow.</p>
                                                                <h1 className="font-bold">. Clear and informative paragraphs</h1>
                                                                <p className="my-4 text-slate-700 text-md">As with headlines, readers like to skim paragraphs to quickly find the information they need. Large blocks of text are daunting and difficult to read.
                                                                    Nielsen Norman Group's Eye-Tracking study found that users read in an F-shaped pattern.</p>
                                                                <div className='py-10'>
                                                                    <img src={require('../../assets/img/schema.jpeg')} alt="schema" title="schema" />
                                                                    <span className='font-bold my-5'>Schema</span>
                                                                </div>
                                                                <p className="my-4 text-slate-700 text-md">Keep paragraphs of text short and start with the main idea. Clarity and conciseness are the keys to excellent readability and a good user experience. The more white space, the better the user experience.</p>
                                                                <h1 className="font-bold">. Bulleted and numbered lists</h1>
                                                                <p className="my-4 text-slate-700 text-md">Break up long sections with bulleted or numbered lists, but also graphics.Lists visually offset information, making it easier for users to navigate the page. Also, it can be helpful to use lists to guide the reader through instructions. </p>
                                                                <h1 className="font-bold">. Short sentences</h1>
                                                                <p className="my-4 text-slate-700 text-md">Shorter sentences make the reader's job easier and your message more digestible.</p>
                                                                <h1 className="font-bold">. Subtopics in the text</h1>
                                                                <p className="my-4 text-slate-700 text-md">Subtopics in your text should be inspired by your keyword research. If users are searching for a specific query, they probably have additional questions that you can answer.Be sure to use H2s, H3s and H4s to include related subtopics. It is also important to structure these sub-topics in a logical order</p>
                                                                <h1 className="font-bold">. Simple vocabulary</h1>
                                                                <p className="my-4 text-slate-700 text-md">Be sure to limit the use of overly complicated words. You can educate your readers on industry and brand terms, but it's important to keep it simple.</p>
                                                                <p className="my-4 text-slate-700 text-md">
                                                                    <span>If you want to check the readability score, use the </span>
                                                                    <a href='https://www.link-assistant.com/website-auditor/seo-content-editor.html' className='text-blue-700'>SEO Writing Assistant</a>
                                                                </p>
                                                                <h1 className="font-bold">8. Include visuals</h1>
                                                                <p className="my-4 text-slate-700 text-md">Use visuals throughout your page - videos, images, graphics, infographics, etc. In 2019, 74% of marketers used visuals in over 70% of their content; simply because it works.</p>
                                                                <h1 className="font-bold">9. Include CTAs (calls-to-action)</h1>
                                                                <p className="my-4 text-slate-700 text-md">
                                                                    One of the main goals of copywriting is to move users along their customer journey.
                                                                    <br /><br />
                                                                    This is where calls-to-action come in handy.
                                                                    <br /><br />
                                                                    Remember the term "search intent"? A user arrives on your page for a specific reason. If they were looking for informational content, you may want them to go to a product page next.
                                                                    <br /><br />
                                                                    Use CTAs to show your users what they might be looking for next. You can use simple links, buttons, images, etc.
                                                                    <br /><br />
                                                                    From a copywriting perspective, they can improve your copy's conversion rate and your content's ROI.
                                                                    <br /><br />
                                                                    From an SEO perspective, they can improve page views, reduce bounce rate and send positive engagement signals to Google.
                                                                </p>
                                                                <h1 className="font-bold">10. A good internal and external link structure</h1>
                                                                <p className="my-4 text-slate-700 text-md">Google's robots are blocked when they arrive on a page that does not contain links to other pages. Users work in a similar way. If they don't have clear steps to follow, they don't necessarily want to spend time and effort.</p>
                                                                <p className="my-4 text-slate-700 text-md">But why drive people away from your page with internal and external links?</p>
                                                                <p className="my-4 text-slate-700 text-md">• By linking to external content, you can build trust around your text. A link to another site is essentially a vote of confidence for that site. It helps to back up your claims with other authoritative websites. And who knows, you might get a backlink yourself.</p>
                                                                <p className="my-4 text-slate-700 text-md">• By placing links to internal content, you guide your user to the next logical steps (where, ideally, they will turn into a customer). You can also improve your site's link structure by linking to pages related to your content.</p>
                                                            </p>
                                                            <p className="my-4 text-blue-700 text-md">• For more details I redirect to the complete tuto </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                                <button
                                                    type="button"
                                                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                                    onClick={() => setShowModal(false)}
                                                >
                                                    CLOSE
                                                </button>
                                                <a
                                                    type="button"
                                                    className="inline-flex w-full justify-center rounded-md border-gray-500 border-transparent -600 px-4 py-2 text-base font-medium border-2 text-black shadow-sm border-spacing-1 sm:ml-3 sm:w-auto sm:text-sm"
                                                    href='https://fr.semrush.com/blog/guide-redaction-seo/'
                                                >
                                                    Full course
                                                </a>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>
                </>
            ) : null}
        </>
    );
}