/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useLayoutEffect, useMemo, useState } from 'react'
import CashRigisterCard from '../components/cash_register/cash_register_card'
import { useCashRegister } from '../store/actions/cashRegisterAction'
import { useRecoilValue } from 'recoil';
import { cash_register_card, cash_register_shopping_list, cash_register_sizes_of_product } from '../store/atoms/cashRegisterStore';
import ModalForItem from '../components/cash_register/modal_for_item';
import { useFormatter } from '../utils/useFormatter';
import _ from 'lodash';
import { TrashIcon } from '@heroicons/react/24/solid'




const CashRegister = memo(() => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isDataModal, setDataModal] = useState<boolean>(false);
  const [isDataSizesLoaded, setIsDataSizesLoaded] = useState<boolean | null>(null);
  const [selectedItemInShoppingList, setSelectedItemInShoppingList] = useState<any | null>(null);
  const [inputDiscount, setinputDiscount] = useState<any | null>(null);

  const use_cash_register = useCashRegister();

  const { products } = useRecoilValue(cash_register_card);
  const { Sizes } = useRecoilValue(cash_register_sizes_of_product);
  const { items } = useRecoilValue(cash_register_shopping_list);

  const onClickInImgOfProduct = useCallback((el: any) => {
    setIsOpenModal(true);
    setDataModal(el);
  }, [isOpenModal]);

  const onClickToGetSizeOfCollection = useCallback(async (id: any) => {
    setIsDataSizesLoaded(false);
    await use_cash_register.getSizeOfProduct(id).finally(() => {
      setIsDataSizesLoaded(true);
    });
  }, []);

  const handleAddItemToShoppingList = useCallback(async (item: any, sz: any) => {
    await use_cash_register.addItemInShoppingList(item, sz);
  }, [items]);

  const getAllProductsInStore = useCallback(async () => {
    await use_cash_register.getProducts();
  }, [])

  const closeModalProductAndInitData = useCallback(async () => {
    await use_cash_register.closeModalOfProduct().finally(() => {
      setIsOpenModal(false);
      setIsDataSizesLoaded(null);
    });
  }, []) 

  const deleteCurrentTicket = useCallback(() => {
    use_cash_register.deleteCurrentTicket().finally(() => {
      setinputDiscount(null);
      setSelectedItemInShoppingList(null);
    });
  }, [inputDiscount, items]);

  const updateQtyOrDeleteItemInShoppingList = useCallback(async (it: any, nm: any) => {
    await use_cash_register.updateQtyOrDeleteItemInShoppingList(it, nm, setSelectedItemInShoppingList, setinputDiscount);
  }, [selectedItemInShoppingList, items])

  const onSelectItem = useCallback(async (itm: any) => {
    setSelectedItemInShoppingList(selectedItemInShoppingList && (selectedItemInShoppingList[0].id === itm[0].id && selectedItemInShoppingList[1].id === itm[1].id) ? null : itm);
    setinputDiscount(null)
    // console.log(selectedItemInShoppingList[0].id);
  }, [selectedItemInShoppingList])

  const returnSumTotal = useMemo(() => {
    return _.sumBy(items, (o: any) => { return o[0].attributes.price * o[2]; });
  }, [items]);

  useLayoutEffect(() => {
    getAllProductsInStore();
  }, [getAllProductsInStore]);

  return (
    <div className="grid grid-cols-12 gap-4 p-4 h-[90vh]">
      <div className="col-span-4 bg-gray-200 p-4 rounded-md text-black h-full">
        <div className="flex flex-col h-full justify-between">
          <div className=' h-[60vh] overflow-auto'>
            {/* Start item */}
            {items && items.map((itm: any) =>
              <div onClick={() => onSelectItem(itm)} key={Math.random()} className={selectedItemInShoppingList && (selectedItemInShoppingList[0].id === itm[0].id && selectedItemInShoppingList[1].id === itm[1].id) ? `bg-violet-400/50 flex justify-between  overflow-auto p-2 border-b border-gray-200 hover:cursor-pointer` : `flex justify-between  overflow-auto p-2 border-b border-gray-200 hover:bg-violet-400/25 hover:cursor-pointer`}>
                <div className="text-sm font-semibold w-64">{itm[1].attributes.size.attributes.name} | {itm[0].attributes.product.attributes.title}</div>
                <div className="flex items-center">
                  <div className="ml-1 text-xs text-gray-500">Qty {itm[2]}</div>
                </div>
                <div className="flex items-center">
                  <div className="ml-1 text-sm font-semibold w-20 text-right">{useFormatter.format(itm[0].attributes.price)}</div>
                </div>
              </div>
            )}
          </div>
          {/* Start Down section TOtal */}
          <div className='ml-1 justify-items-end'>
            <button className='w-full p-5 bg-green-500/90 font-semibold mb-2 rounded-sm active:bg-green-500/60' >Payer maintenant</button>
            <div className="flex justify-between p-4">
              <div className="font-semibold">Articles </div>
              <div className="flex items-center">
                <div className="ml-1 font-semibold">{items?.length}</div>
              </div>
            </div>
            <div className="flex justify-between p-4">
              <div className="font-semibold">Total </div>
              <div className="flex items-center">
                <div className="ml-1 font-semibold">{useFormatter.format(returnSumTotal)}</div>
              </div>
            </div>
          </div>
          {/* End Down section TOtal */}
          {/* End item */}
        </div>
      </div>
      <div className="col-span-2 bg-gray-200 min-h-max p-4 rounded-md text-black h-full">
        <div className='flex w-full row justify-between'>
          <button onClick={() => deleteCurrentTicket()} disabled={items === null} className=' px-6 p-2 bg-red-500/30 font-semibold mb-2 rounded-md active:bg-gray-300 disabled:bg-gray-300 disabled:text-gray-600' ><TrashIcon className='w-3' /></button>
          <button onClick={() => updateQtyOrDeleteItemInShoppingList(selectedItemInShoppingList, 0)} disabled={selectedItemInShoppingList === null} className=' px-6 p-2 bg-white font-semibold mb-2 rounded-md active:bg-gray-300 disabled:bg-gray-300 disabled:text-gray-600' >-</button>
          <button onClick={() => updateQtyOrDeleteItemInShoppingList(selectedItemInShoppingList, 1)} disabled={selectedItemInShoppingList === null} className=' px-6 p-2 bg-white font-semibold mb-2 rounded-md active:bg-gray-300 disabled:bg-gray-300 disabled:text-gray-600' >+</button>
        </div>
        <div className=' py-0.5 bg-gray-500 my-2 rounded-full' />
        <button className='w-full p-5 bg-white font-semibold mb-2 rounded-md active:bg-gray-300 disabled:bg-gray-400' >Client</button>
        <button className='w-full p-5 bg-white font-semibold mb-2 rounded-md active:bg-gray-300 disabled:bg-gray-400' >Chercher</button>
        {/* <button className='w-full p-5 bg-white font-semibold mb-2 rounded-md active:bg-gray-300 disabled:bg-gray-400' >Produits</button>
        <button className='w-full p-5 bg-white font-semibold mb-2 rounded-md active:bg-gray-300 disabled:bg-gray-400' >Reçu</button> */}
      </div>
      {/* Start section items */}
      <div className="col-span-6 bg-gray-200 p-4 rounded-md text-black  justify-center  pl-16 h-[90vh] overflow-auto">
        {products && products.map((el: any) =>
          <CashRigisterCard key={el.id} item={el} onClickInImgOfProduct={() => onClickInImgOfProduct(el)} />
        )}
      </div>
      {/* End section items */}
      <ModalForItem isOpen={isOpenModal} isDataModal={isDataModal} setCloseModal={closeModalProductAndInitData} onClickToGetSizeOfCollection={onClickToGetSizeOfCollection} isDataSizesLoaded={isDataSizesLoaded} sizes={Sizes} closeModalProductAndInitData={closeModalProductAndInitData} handleAddItemToShoppingList={handleAddItemToShoppingList} />
    </div>
  )
})

export default CashRegister