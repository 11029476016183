/* eslint-disable react-hooks/exhaustive-deps */
import HeaderTable from '../components/HeaderTable'
import { useNavigate } from 'react-router';
import { useCollectionAction } from '../store/actions/collectionAction';
import { useCallback, useLayoutEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { collectionStore } from '../store/atoms/collectionStore';
import moment from 'moment';

const CollectionManagement = () => {

  const [loadingReq, setLoadingReq] = useState(false);

  // Const useUsersManagementAction
  const collectionAction = useCollectionAction();

  // Use a constant navigator.
  const navigate = useNavigate();

  // Use the users management store.
  const { collection }: any = useRecoilValue(collectionStore);

  // Returns all users.
  const getAllProducts = useCallback(async () => {
    return await collectionAction.getAllCollections();
  }, [collection]);

  // handle change purchasable
  const handleChangePurchasable = async (product: any) => {
    await collectionAction.setPurchasable(product, setLoadingReq);
  }

  // handle delete collection in store and cloud.
  const handleDeleteCollection = async (product: any) => {
    await collectionAction.deleteCollection(product);
  }

  // Returns a list of all users.
  useLayoutEffect(() => {
    getAllProducts();
  }, []);

  return (
    <div>
      <HeaderTable
        titlePage='Collection Managements'
        description={`Any action you take will have consequences, so be careful. ${loadingReq ? 'In Progress' : ''}`}
        showInputSearch={false}
        showBtnAction={true}
        titleBtnAction='Add New Collection'
        onPress={() => navigate('/collection/management/add/collection')}
      />
      <div className="bg-white">
        <div className="overflow-x-auto border-x border-t">
          <table className="table-auto w-full">
            <thead className="border-b">
              <tr className="bg-gray-100">
                <th className="text-left p-4 font-medium">
                  Picture
                </th>
                <th className="text-left p-4 font-medium">
                  Name of collection
                </th>
                <th className="text-left p-4 font-medium">
                  Category
                </th>
                <th className="text-left p-4 font-medium">
                  Price
                </th>
                <th className="text-left p-4 font-medium">
                  CreatedAt
                </th>
                <th className="text-left p-4 font-medium">
                  IsPublished
                </th>
                <th className="text-left p-4 font-medium">
                  Delete
                </th>
                <th className="text-left p-4 font-medium">
                  Update
                </th>
              </tr>
            </thead>
            <tbody>
              {collection && collection.map((product: any) =>
                <tr key={Math.random()} className="border-b hover:bg-gray-50">
                  <td className="p-4 self-center" >
                    <img src={product.attributes.product.attributes.img1.url()} className=" w-20 h-30 rounded" alt='avatar for user' />
                  </td>
                  <td className="p-4 self-center">
                    {product.attributes.cname.attributes.name}
                  </td>
                  <td className="p-4 self-center">
                    {product.attributes.category.attributes.name}
                  </td>
                  <td className="p-4 self-center">
                    {product.attributes.price} €
                    <span className='block text-xs w-24 text-gray-400'>
                      ( Without the additional costs of size )
                    </span>
                  </td>
                  <td className="p-4 self-center">
                    <button className="bg-green-100 text-green-800 text-xs font-semibold mr-2 py-0.5 rounded dark:bg-transparent dark:text-gray-600 px-4 truncate w-28">{moment(product.createdAt).fromNow()}</button>
                  </td>
                  <td className="p-4 self-center">
                    {product.attributes.isPublished ?
                      <button onClick={() => handleChangePurchasable(product)} className="bg-green-100 text-green-800 text-xs font-semibold mr-2 py-0.5 rounded dark:bg-green-200 dark:text-green-900 px-4 truncate w-28">Purchasable</button>
                      :
                      <button onClick={() => handleChangePurchasable(product)} className="bg-red-100 text-red-800 text-xs font-semibold mr-2  py-0.5 rounded dark:bg-red-200 dark:text-red-900 pl-[11px] pr-[11px] truncate w-28">Not Purchasable</button>
                    }
                  </td>
                  <td className="p-4 self-center">
                      <button onClick={() => handleDeleteCollection(product)} className="bg-red-100 text-red-800 text-xs font-semibold mr-2  py-0.5 rounded dark:bg-red-200 dark:text-red-900 pl-[11px] pr-[11px] truncate w-28">Delete</button>
                  </td>
                  <td className="p-4 self-center">
                      <button onClick={() => navigate(`/collection/management/update/${product.id}`)} className="bg-red-100 text-red-800 text-xs font-semibold mr-2  py-0.5 rounded dark:bg-blue-200 dark:text-blue-900 pl-[11px] pr-[11px] truncate w-28">Update</button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div >
  )
}

export default CollectionManagement