import { memo, useMemo } from 'react';
import { useFormatter } from '../../utils/useFormatter';

const ModalForItem = memo(({ setCloseModal, isOpen, isDataModal, onClickToGetSizeOfCollection, isDataSizesLoaded, sizes, handleAddItemToShoppingList }: any) => {

    const render_when_data_sizes_null = useMemo(() => {
        return (
            <div className='my-10'>
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => onClickToGetSizeOfCollection(isDataModal.id)}
                >
                    Show sizes
                </button>
            </div>
        )
    }, [isDataModal.id, onClickToGetSizeOfCollection]);

    const render_when_data_sizes_false = useMemo(() => {
        return (
            <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-black focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-200"
                disabled
            >
                Load sizes
            </button>
        )
    }, []);

    const render_when_data_sizes_true = useMemo(() => {
        return (
            <div>
                {sizes && <div className='p-2 flex overflow-auto'>
                    {/* <span className=' font-semibold'>Quantities</span> */}
                    {sizes.map((sz: any) =>
                        <div key={sz.id}>
                            <button onClick={() => handleAddItemToShoppingList(isDataModal, sz)} disabled={sz.attributes.quantity <= 0} className='p-5 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-400 disabled:cursor-not-allowed' name='ctaSz'>Size {sz.attributes.size.attributes.name} :  {sz.attributes.quantity} </button>
                        </div>
                    )}
                </div>}
            </div>
        )
    }, [handleAddItemToShoppingList, isDataModal, sizes]);

    const _render_data_sizes: any = useMemo(() => {
        switch (isDataSizesLoaded) {
            case null:
                return render_when_data_sizes_null
            case false:
                return render_when_data_sizes_false
            case true:
                return render_when_data_sizes_true
            default:
                break;
        }
    }, [isDataSizesLoaded, render_when_data_sizes_false, render_when_data_sizes_null, render_when_data_sizes_true]);

    return (
        <div className="flex items-center justify-center z-50">
            {isDataModal && isOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">​</span>

                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">{isDataModal.attributes.product.attributes.title}</h3>
                                <div className="mt-2">
                                    <div className='w-full flex'>
                                        <img src={isDataModal.attributes.product.attributes.img1.url()} alt={isDataModal.attributes.product.attributes.title} className='w-36 mx-1' />
                                        <img src={isDataModal.attributes.product.attributes.img2.url()} alt={isDataModal.attributes.product.attributes.title} className='w-36 mx-1' />
                                        <img src={isDataModal.attributes.product.attributes.img3.url()} alt={isDataModal.attributes.product.attributes.title} className='w-36 mx-1' />
                                    </div>
                                    <div className=''>
                                        <div className='p-2'>
                                            <span className=' font-semibold'>Details</span>
                                            <p>• Prix : {useFormatter.format(isDataModal.attributes.price)}</p>
                                            <p>• Name : {isDataModal.attributes.cname.attributes.name}</p>
                                            <p>• Categorie : {isDataModal.attributes.category.attributes.name}</p>
                                        </div>
                                        {_render_data_sizes}
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => setCloseModal()}
                                >
                                    Confirmer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
})

export default ModalForItem;
