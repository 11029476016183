/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import moment from 'moment';

export default function ModalDetails({ open, setOpen, data }: any) {

    const cancelButtonRef = useRef(null);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <h1 className='w-full text-center justify-center mb-3 font-bold'>Details of condidate</h1>
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50" />
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    <tr className="divide-x divide-gray-200">
                                        <td className="py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">Name</td>
                                        <td className="py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">{data.attributes.email}</td>
                                    </tr>
                                    <tr className="divide-x divide-gray-200">
                                        <td className="py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">First Name</td>
                                        <td className="py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">{data.attributes.firstName}</td>
                                    </tr>
                                    <tr className="divide-x divide-gray-200">
                                        <td className="py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">Phone</td>
                                        <td className="py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">{data.attributes.phone}</td>
                                    </tr>
                                    <tr className="divide-x divide-gray-200">
                                        <td className="py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">Apply for</td>
                                        <td className="py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">{data.attributes.job.attributes.title}</td>
                                    </tr>
                                    <tr className="divide-x divide-gray-200">
                                        <td className="py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">Street</td>
                                        <td className="py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">{data.attributes.street}</td>
                                    </tr>
                                    <tr className="divide-x divide-gray-200">
                                        <td className="py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">Email</td>
                                        <td className="py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">{data.attributes.email}</td>
                                    </tr>
                                    <tr className="divide-x divide-gray-200">
                                        <td className="py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">CreatedAt</td>
                                        <td className="py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">{moment(data.attributes.createdAt).fromNow()}</td>
                                    </tr>
                                    <tr className="divide-x divide-gray-200">
                                        <td className="py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">Cv</td>
                                        <td className="py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">
                                            {data.attributes.cv !== undefined ? <a href={data.attributes.cv._url} target="_blank" rel="noreferrer" className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full' download>READ</a> : <a href='#' className='bg-gray-400 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded-full'>NULL</a>}
                                        </td>
                                    </tr>
                                    <tr className="divide-x divide-gray-200">
                                        <td className="py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">More information</td>
                                        <td className=" py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">
                                            <p>{data.attributes.info ? data.attributes.info : '--'}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className=' w-full'>
                                <button onClick={() => setOpen(false)} className="z-10 inset-0 overflow-y-auto mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Close</button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
