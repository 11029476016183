import { atom } from 'recoil';

interface currentUserType {
    categorys: any | undefined,
}

export const currentCategorys = atom({
    key: 'current-categorys-store',
    default: {
        categorys: undefined,
    } as currentUserType,
});