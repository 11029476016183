/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { memo } from 'react';

const CashRigisterCard = memo(({item, onClickInImgOfProduct}: any) => {
  return (
    <button onClick={() => onClickInImgOfProduct()} className="w-[8rem] h-[10rem] rounded overflow-hidden shadow-lg m-2 hover:bg-gray-700/60 hover:border-cyan-300">
      <img className="w-full h-[13rem] bg-cover" src={item.attributes.product.attributes.img1.url()} alt="Image du produit"/>
    </button>
  );
})

export default CashRigisterCard;
