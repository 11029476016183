import React from 'react'
import HeaderTable from '../components/HeaderTable'
import { useNavigate } from 'react-router';
import ListToolsManagement from '../components/toolsManagement';

function ToolsManagement() {
    // Use a constant navigator.
    const navigate = useNavigate();

    const people = [
        { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
        // More people...
    ]
    return (
        <div>
            <HeaderTable
                titlePage='Tools management'
                description={`Here you can view and manage existing tools and add new tools.`}
                showInputSearch={false}
                showBtnAction={true}
                titleBtnAction="New tools"
                onPress={(e) => navigate('/tools/management/add/tools')}
            />
            <ListToolsManagement people={people} />
        </div>
    )
}

export default ToolsManagement