import { useCallback, useMemo, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react"; // tinymce-react is imported as an ES module
import HeaderTable from "../components/HeaderTable";
import { useNavigate } from "react-router";
import { useArticlesManagement } from "../store/actions/articlesManagement";
import { getBase64 } from "../utils/convertToBase64";
import ModalCondition from "../components/articleManagement/ModalCondition";


export default function WriteArticle() {
    // Creates a formulary.
    const formulary = useMemo(() => {
        return ({
            title: '',
            summarize: '',
            pic: {
                file: '',
                base64: '',
            },
            content: '',
            inputTags: '',
            slug: '',
            tags: [] as any,
        })
    }, []);

    // Returns a const for the given formulary.
    const [formArticle, setArticle]: any = useState(formulary);

    const [showModal, setShowModal]: any = useState(false);

    const [loadingSaveArticle, setLoadingSaveArticle]: any = useState(false);

    // Use a constant editorRef.
    const editorRef: any = useRef(null);

    // Const use articles management.
    const useArticles = useArticlesManagement();

    // Use a constant navigator.
    const navigate = useNavigate();

    // Adds tags to the article.
    const handleAddTagsInArray = useCallback((e: any) => {
        if (e.key === 'Enter') {
            const newArr = { ...formArticle };
            newArr.tags.unshift(formArticle.inputTags.trim());
            setArticle({ ...newArr, inputTags: '' });
        }
    }, [formArticle])

    // Deletes an article.
    const handleDeleteTag = useCallback((tag: any, idx: any) => {
        const newArr = { ...formArticle };
        newArr.tags.shift(tag);
        setArticle({ ...newArr });
    }, [formArticle])

    // Handle change text input.
    const handleChangeTextInput = async (e: any) => {
        if (e.name === 'title') {
            formArticle[e.name] = e.value;
            formArticle.slug = e.value.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');;
            return;
        }
        formArticle[e.name] = e.value;
    }

    const handleToggleModalCondition = useCallback(() => {
        setShowModal(!showModal);
    }, [showModal])

    // Handle change text input.
    const handleChangeFileInput = useCallback(async (e: any) => {
        formArticle.pic.file = e.target.files[0];
        await getBase64(formArticle.pic.file).then(result => {
            formArticle.pic.base64 = result;
        }).catch(err => {
            console.log(err);
        });
        formArticle.pic.file = e.target.files[0];
    }, [formArticle.pic]);

    // Saves the form article.
    const handleSubmitSaveArticle = useCallback(async () => {
        if (editorRef.current) formArticle.content = editorRef.current.getContent();
        try {
            await useArticles.Save(formArticle, setLoadingSaveArticle);
            setArticle(formulary);
        } catch (e: any) {
            console.log(e.message);
        }
    }, [formArticle, useArticles, editorRef, formulary]);

    return (
        <div className="w-full grid h-screen place-items-center">
            <HeaderTable
                titlePage='Write article'
                description='Be careful when publishing an article to make sure the spelling is correct  '
                showInputSearch={false}
                showBtnAction={true}
                titleBtnAction="Return to back"
                onPress={(e) => navigate('/blog/management')}
            />
            <div className=" justify-end flex w-[50rem]">
                <button disabled className=" disabled:text-white disabled:hover:bg-gray-200 disabled:cursor-not-allowed bg-gray-200 px-3 py-1  mb-2 rounded-lg ml-2 text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300">Save and Publish</button>
                <button onClick={() => handleToggleModalCondition()} className=" disabled:text-white disabled:hover:bg-gray-200 disabled:cursor-not-allowed bg-gray-200 px-3 py-1  mb-2 rounded-lg ml-2 text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300">Writing tips</button>
                <button onClick={() => handleSubmitSaveArticle()} className=" bg-gray-200 px-3 py-1  mb-2 rounded-lg ml-2 text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300">{loadingSaveArticle ? 'Loading...' : 'Just Save'}</button>
            </div>
            <div className="ml-3 w-[50rem] justify-center">
                <label className=" text-gray-400 text-sm">Title of article</label>
                <input required onChange={(e: any) => handleChangeTextInput(e.target)} defaultValue={formArticle.title} className="bg-gray-100 p-3 outline-none mb-2 w-full block " type="text" name="title" id="" placeholder="Title" />
            </div>
            <div className="ml-3 w-[50rem] justify-center">
                <label className=" text-gray-400 text-sm">Summarize the article </label>
                <textarea required onChange={(e: any) => handleChangeTextInput(e.target)} defaultValue={formArticle.summarize} rows={4} cols={50} className="bg-gray-100 p-3 outline-none mb-2 w-full block resize-none " name="summarize" id="" placeholder="Summarize" />
            </div>
            <div className="ml-3  w-[50rem]">
                <label className=" text-gray-400 text-sm">Cover of article</label>
                <input required onChange={handleChangeFileInput} defaultValue={formArticle.file} className="bg-gray-100 p-3 outline-none mb-2 w-full block " type="file" name="file" id="" placeholder="search..." />
            </div>
            <div className="mx-3  w-[50rem]">
                <label className=" text-gray-400 text-sm">Content of article</label>
                <Editor
                    apiKey="5q745td1hp1gy5mgpcvjjyx5tywbhzehk8xmg45frm8n6rvw"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={formArticle.content}
                    init={{
                        height: 600,
                        menubar: true,
                        plugins:
                            "image link table lists autolink searchreplace directionality visualblocks visualchars media  codesample charmap pagebreak nonbreaking anchor insertdatetime autosave wordcount emoticons",
                        toolbar:
                            "undo redo formatpainter | blocks fontfamily fontsize | bold italic underline forecolor backcolor | link image addcomment showcomments  | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | removeformat",
                        toolbar_sticky: true,
                        icons: "thin",
                        skin: "material-classic",
                        content_style: "material-classic",
                    }}
                />
            </div>
            <div className="ml-3  w-[50rem]">
                <div className="flex py-7 px-2 my-5 border-gray-400 border-2 rounded-lg">
                    <span id="badge-dismiss-dark" className="inline-flex items-center py-1 px-2 mr-2 text-sm font-medium text-gray-800 bg-gray-100 rounded dark:bg-gray-200 dark:text-gray-800">Your Tags :</span>
                    {formArticle.tags && formArticle.tags.map((tag: any, idx: any) => (
                        <span key={Math.random()} id="badge-dismiss-dark" className="inline-flex items-center py-1 px-2 mr-2 text-sm font-medium text-gray-800 bg-gray-100 rounded dark:bg-gray-200 dark:text-gray-800">
                            {tag}
                            <button onClick={(e) => handleDeleteTag(tag, idx)} type="button" className="inline-flex items-center p-0.5 ml-2 text-sm text-gray-400 bg-transparent rounded-sm hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-300 dark:hover:text-gray-900" data-dismiss-target="#badge-dismiss-dark" aria-label="Remove">
                                <span className="px-1">☓</span>
                            </button>
                        </span>
                    ))}
                </div>
                <label className=" text-gray-500 text-sm">Add tags in article</label>
                <pre className="py-2 text-gray-400 text-xs">
                    Press &nbsp;
                    <kbd className="px-1 py-0.5 text-xs font-semibold text-gray-300 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-100 dark:text-gray-500 dark:border-gray-300 ">Enter</kbd>
                    &nbsp; to add the tag in the article
                </pre>
                <input value={formArticle.inputTags} onKeyUp={(e) => formArticle.inputTags.trim() !== '' && handleAddTagsInArray(e)} onChange={(e) => setArticle({ ...formArticle, inputTags: e.target.value })} className="bg-gray-100 p-3 outline-none w-full block mb-10" type="text" name="" id="" placeholder="tags" />
            </div>
            <ModalCondition setShowModal={setShowModal} showModal={showModal} />
        </div >
    );
}