import { atom } from 'recoil';

interface cash_register_card_type {
    products: any | undefined
}

export const cash_register_card = atom({
    key: 'cash-register-management-store',
    default: {
        products: undefined,
    } as cash_register_card_type,
});

interface cash_register_sizes_type {
    Sizes: any | undefined
}

export const cash_register_sizes_of_product = atom({
    key: 'cash-register-management-sizes-store',
    default: {
        Sizes: undefined,
    } as cash_register_sizes_type,
});

interface cash_register_shopping_list_type {
    items: Array<Object> | undefined
}

export const cash_register_shopping_list = atom({
    key: 'cash-register-management-shopping-list',
    default: {
        items: [],
    } as cash_register_shopping_list_type,
});