/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useState } from 'react'
import HeaderTable from '../components/HeaderTable'
import { useNavigate } from 'react-router';
import { useCollectionAction } from '../store/actions/collectionAction';

function AddNewCollectionName() {
  const [loadingData, setLoadingData] = useState(false);
  const [formCN, setFormCN] = useState({
    name: '',
    SD: ''
  });

  // init category action
  const collectionAction = useCollectionAction();

  // handle change text in state
  const handleChangeTextInput = useCallback((e: any) => {
    setFormCN({ ...formCN, [e.name]: e.value })
  }, [formCN]);


  const handleSubmitData = useCallback(async () => {
    try {
      await collectionAction.setNewCollectionName(formCN, setLoadingData);
    } catch (e: any) {
      console.log(e.message);
    }
  }, [formCN, collectionAction, setLoadingData]);

  // Use a constant navigator.
  const navigate = useNavigate();

  return (
    <div className='w-full grid place-items-center'>
      <HeaderTable
        titlePage='Collection Name Managements'
        description={`Any action you take will have consequences, so be careful.`}
        showInputSearch={false}
        showBtnAction={true}
        titleBtnAction='Return to collection name'
        onPress={() => navigate('/collectionName/management')}
      />
      <div>
        <div className=" justify-end flex w-[50rem]">
          <button onClick={() => handleSubmitData()} className="bg-gray-200 px-3 py-1  mb-2 rounded-lg ml-2 text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300">{loadingData ? 'In Progress' : 'Just Save'}</button>
        </div>
        <div className="ml-3 w-[50rem] justify-center">
          <label className=" text-gray-400 text-sm">Name collection name</label>
          <input required onChange={(e: any) => handleChangeTextInput(e.target)} className="bg-gray-100 p-3 outline-none mb-2 w-full block " type="text" name="name" id="" placeholder="name of category" />
        </div>
        <div className="ml-3 w-[50rem] justify-center">
          <label className=" text-gray-400 text-sm">Small Description</label>
          <input required onChange={(e: any) => handleChangeTextInput(e.target)} className="bg-gray-100 p-3 outline-none mb-2 w-full block " type="text" name="SD" id="" placeholder="small description of category" />
        </div>
      </div>
    </div>
  )
}

export default AddNewCollectionName