import { atom } from 'recoil';

interface currentTeamStore {
    team: any | undefined,
}

interface currentDelayStore {
    delays: any | undefined,
}

export const currentTeam = atom({
    key: 'current-team-store',
    default: {
        team: undefined,
    } as currentTeamStore,
});


export const currentDelays = atom({
    key: 'current-delay-store',
    default: {
        delays: undefined,
    } as currentDelayStore,
});