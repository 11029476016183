import Parse from "parse";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { jobsStore } from "../atoms/jobsStore";
import { toast } from "react-toastify";

interface interUseUserActions {
    saveJob(formulary?: any, loadingSave?: any): Promise<any>;
    deleteJob(formulary?: any, loadingSave?: any): Promise<any>;
    getAllJobs(value?: any): Promise<any>;
    hiddenJob(selectedJob?: any, setLoadingUpdate?: any): Promise<any>;
    showJob(selectedJob?: any, setLoadingUpdate?: any): Promise<any>;
}

export function useJobsManagement(): interUseUserActions {
    const jobsStores = useSetRecoilState(jobsStore);
    const { jobs } = useRecoilValue(jobsStore);
    return {
        saveJob,
        deleteJob,
        getAllJobs,
        hiddenJob,
        showJob,
    };

    // Get a list of all jobs
    async function saveJob(formulary?: any, loadingSave?: any): Promise<any> {
        try {
            loadingSave(true)
            const res = await Parse.Cloud.run('addNewJob', { newJob: formulary });
            loadingSave(false)
            if (res.id !== undefined) return toast('Job registered with success.');
        } catch (e: any) {
            toast(e.message);
            loadingSave(false)
        }
    }

    // Get a list of all jobs
    async function deleteJob(job?: any, loadingSave?: any): Promise<any> {
        try {
            // Remove users with the same username.
            var copyArrOrginal: any = [...jobs];

            // Returns a copy of the article store whose slug is the same as the current one.
            const copyStore = jobs.filter((el: any) => el.id !== job.id);

            // ArticleStore is a copy of the articleStore
            jobsStores({ jobs: [...copyStore], count: jobs.length })

            // Delete a job.
            const res = await Parse.Cloud.run('deleteJob', { id: job.id });

            if (res.id !== undefined) return toast('Job deleted with success.');
        } catch (e: any) {
            toast(e.message);
            jobsStores({ jobs: [...copyArrOrginal], count: copyArrOrginal.length })
        }
    }

    // Get a list of all jobs
    async function getAllJobs(value?: any): Promise<any> {
        try {
            let result = await Parse.Cloud.run('getListJobsForAdmin');
            jobsStores({ count: result.length, jobs: result })
        } catch (e: any) {
            console.log(e.message)
        }
    }

    async function hiddenJob(selectedJob?: any, setLoadingUpdate?: any): Promise<any> {
        try {
            // Sets the loading update flag to true.
            setLoadingUpdate(true);

            // Returns true if any of the articles has the same slug as the el.
            const find = jobs.find((el: any) => el.id === selectedJob.id);

            // Returns true if any of the jobs has the same slug.
            const findIndex = jobs.findIndex((el: any) => el.id === selectedJob.id);

            // Returns a copy of the article store whose slug is the same as the current one.
            const copyStore = jobs.filter((el: any) => el.id !== selectedJob.id);

            // rebuild object
            const obj = {
                ...find,
                attributes: { ...find.attributes, isPublished: false },
                createdAt: { ...find.createdAt },
                updatedAt: { ...find.updatedAt }
            };

            // Creates a copy of the store.
            const newArr = [...copyStore];

            // add an element in the idx of a new array.
            newArr.splice(findIndex, 0, obj);

            jobsStores({ jobs: [...newArr], count: jobs.length })
            await Parse.Cloud.run('hiddenJob', { id: selectedJob.id });
            setLoadingUpdate(false);
        } catch (e: any) {
            console.log(e.message);
            setLoadingUpdate(false);
        }
    }

    async function showJob(selectedJob?: any, setLoadingUpdate?: any): Promise<any> {
        try {
            // Sets the loading update flag to true.
            setLoadingUpdate(true);

            // Returns true if any of the articles has the same slug as the el.
            const find = jobs.find((el: any) => el.id === selectedJob.id);

            // Returns true if any of the jobs has the same slug.
            const findIndex = jobs.findIndex((el: any) => el.id === selectedJob.id);

            // Returns a copy of the article store whose slug is the same as the current one.
            const copyStore = jobs.filter((el: any) => el.id !== selectedJob.id);

            // rebuild object
            const obj = {
                ...find,
                attributes: { ...find.attributes, isPublished: true },
                createdAt: { ...find.createdAt },
                updatedAt: { ...find.updatedAt }
            };

            // Creates a copy of the store.
            const newArr = [...copyStore];

            // add an element in the idx of a new array.
            newArr.splice(findIndex, 0, obj);

            jobsStores({ jobs: [...newArr], count: jobs.length })
            await Parse.Cloud.run('showJob', { id: selectedJob.id });
            setLoadingUpdate(false);
        } catch (e: any) {
            console.log(e.message);
            setLoadingUpdate(false);
        }
    }
}