import { atom } from 'recoil';

interface productStoreType {
    products: any | undefined,
}

export const productStore = atom({
    key: 'product-management-store',
    default: {
        products: undefined,
    } as productStoreType,
});