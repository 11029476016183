/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { memo, useCallback, useLayoutEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import HeaderTable from '../components/HeaderTable'
import { useUsersManagementAction } from '../store/actions/usersManagement'
import { currentUser } from '../store/atoms/currentUser';
import { usersManagementStore } from '../store/atoms/usersManagement';


const UsersManagement = memo(() => {
    const [loadingBanAndUnban, setLoadingBanAndUnban] = useState(false);

    // Const useUsersManagementAction
    const usersAction = useUsersManagementAction();

    // Returns all users.
    const returnAllUsers = useCallback(async () => {
        return await usersAction.getUsers();
    }, [usersAction]);

    // Returns a list of all users.
    useLayoutEffect(() => {
        returnAllUsers();
    }, []);

    // Use the users management store.
    const { users, counts }: any = useRecoilValue(usersManagementStore);

    // Use the users management store.
    const currentuser: any = useRecoilValue(currentUser);

    // Handle a submit action ban a user in the platform.
    const handleSubmitActionBanned = useCallback(async (username: any, setLoadingBanAndUnban: any) => {
        await usersAction.bannedUserInPlatform(username, setLoadingBanAndUnban);
    }, [usersAction])

    // Handle a submit action unbanned by a user.
    const handleSubmitActionUnbanned = useCallback(async (username: any, setLoadingBanAndUnban: any) => {
        await usersAction.unbannedUserInPlatform(username, setLoadingBanAndUnban);
    }, [usersAction])

    // Deletes the account of the current user.
    const handleSubmitDeleteAccountOfUser = useCallback((username: any, setLoadingBanAndUnban: any) => {
        usersAction.deleteAccountOfUser(username, setLoadingBanAndUnban);
    }, [usersAction])

    // Handle a submit get more users
    const handleSubmitGetMoreUsers = async () => {
        await usersAction.getMoreUsers(users.length);
    }

    return (
        <div>
            <HeaderTable
                titlePage='Users Managements'
                description={`Any action you take will have consequences, so be careful. ${loadingBanAndUnban ? '| Registration in progress...' : ''}`}
                showInputSearch={true}
                showBtnAction={false}
            />
            <div className="bg-white">
                <div className="overflow-x-auto border-x border-t">
                    <table className="table-auto w-full">
                        <thead className="border-b">
                            <tr className="bg-gray-100">
                                <th className="text-left p-4 font-medium">
                                    Uuid
                                </th>
                                <th className="text-left p-4 font-medium">
                                    Name
                                </th>
                                <th className="text-left p-4 font-medium">
                                    Email
                                </th>
                                <th className="text-left p-4 font-medium">
                                    Roles
                                </th>
                                <th className="text-left p-4 font-medium">
                                    CreatedAt
                                </th>
                                <th className="text-left p-4 font-medium">
                                    Confirmed email
                                </th>
                                <th className="text-left p-4 font-medium">
                                    Status
                                </th>
                                <th className="text-left p-4 font-medium">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {users && users.map((user: any) =>
                                <tr key={user.id} className="border-b hover:bg-gray-50">
                                    <td className="p-4 self-center" >
                                        <img src={user.attributes.avatar.url()} className=" w-8 h-8 rounded-full" alt='avatar for user' />
                                    </td>
                                    <td className="p-4 self-center" onCut={() => handleSubmitDeleteAccountOfUser(user.attributes.username, setLoadingBanAndUnban)}>
                                        {user.attributes.username}
                                    </td>
                                    <td className="p-4 self-center">
                                        {user.attributes.email}
                                    </td>
                                    <td className="p-4 self-center">
                                        {user.attributes.roles}
                                    </td>
                                    <td className="p-4 self-center">
                                        {moment(user.createdAt).format("MMM Do YY")}
                                    </td>
                                    <td className="p-4 self-center">
                                        {user.attributes.emailVerified ?
                                            <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">Confirmed</span>
                                            :
                                            <span className="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900">Unconfirmed</span>
                                        }
                                    </td>
                                    <td className="p-4 self-center">
                                        {user.attributes.isBanned ?
                                            <span className="bg-red-100 text-red-800 text-xs font-semibold px-4 py-0.5 rounded dark:bg-red-200 dark:text-red-900">Banned</span>
                                            :
                                            <span className="bg-green-100 text-green-800 text-xs font-semibold px-5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">Active</span>
                                        }
                                    </td>
                                    <td className="p-4 self-center row flex">
                                        {user.attributes.isBanned ?
                                            <button onClick={
                                                () => (currentuser.uuid !== user.id || user.attributes.roles[0] === "ADMIN") && handleSubmitActionUnbanned(user.attributes.username, setLoadingBanAndUnban)} disabled={currentuser.uuid === user.id || user.attributes.roles[0] === "ADMIN"} className=' disabled:bg-slate-400 mx-1 group relative flex bg-green-500 p-1 text-white justify-center rounded-md w-20'>Turn-on</button>
                                            :
                                            <button onClick={() => (currentuser.uuid !== user.id || user.attributes.roles[0] === "ADMIN") && handleSubmitActionBanned(user.attributes.username, setLoadingBanAndUnban)} disabled={currentuser.uuid === user.id || user.attributes.roles[0] === "ADMIN"} className='disabled:bg-slate-400 mx-1 group relative flex bg-red-500 p-1 text-white justify-center rounded-md w-20'>Suspend </button>
                                        }
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {users && users.length < counts && <button onClick={() => handleSubmitGetMoreUsers()} className='block w-full bg-slate-300 p-2 mt-2 text-gray-500'>SHOW MORE</button>}
        </div >


    )
})

export default UsersManagement