import FlexCard from "../components/FlexCard"
import HeaderTable from "../components/HeaderTable"

const Home = () => {
    return (
        <div>
            <HeaderTable
                titlePage='Welcome to Evaxio'
                description="You can see all information in dashboard"
                showInputSearch={false}
                showBtnAction={false}
            />
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 p-4 gap-4 justify-center w-full">
                <FlexCard title="Blog" subTitle="Articles" count={290} />
                <FlexCard title="Users" subTitle="Not blocked" count={1555} />
                <FlexCard title="Users" subTitle="Blocked" count={134} />
                <FlexCard title="Users" subTitle="Blocked" count={134} />
            </div>
            <div>Home</div>
        </div>
    )
}

export default Home