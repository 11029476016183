import { atom } from 'recoil';

interface articlesStoreType {
    count: number | undefined,
    articles: any | undefined,
}

export const articlesStore = atom({
    key: 'articles-management-store',
    default: {
        count: undefined,
        articles: undefined,
    } as articlesStoreType,
});