import React, { memo } from 'react'

interface HeaderTableType {
    titlePage: string,
    description: string,
    showInputSearch?: boolean,
    showBtnAction: boolean,
    titleBtnAction?: string,
    onPress?: (e: any) => any,
}

const HeaderTable = memo(({ titlePage, description, showInputSearch, showBtnAction, titleBtnAction, onPress }: HeaderTableType) => {
    return (
        <div className="bg-white p-8 rounded-md w-full">
            <div className=" flex items-center justify-between pb-6">
                <div>
                    <h2 className="text-gray-800 text-xl font-semibold">{titlePage}</h2>
                    <span className="text-xs">{description}</span>
                </div>
                <div className="flex items-center justify-between">
                    {showInputSearch && <div className="flex bg-gray-50 items-center p-2 rounded-md">
                        <input className="bg-gray-50 outline-none ml-1 block " type="text" name="" id="" placeholder="search..." />
                    </div>}
                    {showBtnAction && <div className="lg:ml-40 ml-10 space-x-8">
                        <button onClick={(e) => onPress && onPress(e)} className="bg-gray-200 px-4 py-2 self-center rounded-full text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300">{titleBtnAction}</button>
                    </div>}
                </div>
            </div>
        </div>
    )
})

export default HeaderTable