import { atom } from 'recoil';

interface candidateType {
    candidate: any | undefined,
    counts: any | undefined
}

export const candidateManagementStore = atom({
    key: 'candidate-management-store',
    default: {
        candidate: undefined,
        counts: undefined,
    } as candidateType,
});