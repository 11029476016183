import Parse from "parse";
import { toast } from "react-toastify";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { articlesStore } from "../atoms/articlesStore";

interface articlesType {
    Save(formArticle?: any, setLoadingSaveArticle?: any): Promise<any>;
    getArticles(v?: any): Promise<any>;
    deleteThisArticle(v?: any): Promise<any>;
    hiddenArticle(v?: any, s?: any): Promise<any>;
    showArticle(v?: any, s?: any): Promise<any>;
}

export function useArticlesManagement(): articlesType {
    const articleStore: any = useSetRecoilState(articlesStore);
    const { articles, count }: any = useRecoilValue(articlesStore);
    return {
        Save,
        getArticles,
        deleteThisArticle,
        hiddenArticle,
        showArticle
    };

    // Save an article to the cloud
    async function Save(formArticle?: any, setLoadingSaveArticle?: any) {
        try {
            setLoadingSaveArticle(true);
            const res = await Parse.Cloud.run('writeArticle', { formArticle });
            setLoadingSaveArticle(false);
            if (res.id !== undefined) return toast('Article registered with success.');
            throw new Error(res);
        } catch (e: any) {
            toast(e.message);
            setLoadingSaveArticle(false);
        }
    };

    // Gets articles.
    async function getArticles(v?: any) {
        try {
            let result = await Parse.Cloud.run('getArticles');
            articleStore({ count: result.countArticles, articles: result.result })
        } catch (e: any) {
            console.log(e.message)
        }
    };

    // Delete this article.
    async function deleteThisArticle(article?: any) {
        // Remove users with the same username.
        const copyArrOrginal = [...articles];

        // Returns a copy of the article store whose slug is the same as the current one.
        const copyStore = articles.filter((el: any) => el.attributes.slug !== article.attributes.slug);

        // ArticleStore is a copy of the articleStore
        articleStore({ articles: [...copyStore], ...count })

        // Deletes an article.
        const result = await Parse.Cloud.run('deleteArticle', { slug: article.attributes.slug });

        try {
            // Return true if the result is true.
            if (result === true) return;

            // Throws an error.
            throw new Error(result);
        } catch (e: any) {
            toast(e.message);
            articleStore({ articles: [...copyArrOrginal], ...count })
        }
    };

    // Hidden the given article.
    async function hiddenArticle(article?: any, setLoadingUpdate?: any) {
        try {
            // Sets the loading update flag to true.
            setLoadingUpdate(true);

            // Returns true if any of the articles has the same slug as the el.
            const find = articles.find((el: any) => el.attributes.slug === article.attributes.slug);

            // Returns true if any of the articles has the same slug.
            const findIndex = articles.findIndex((el: any) => el.attributes.slug === article.attributes.slug);

            // Returns a copy of the article store whose slug is the same as the current one.
            const copyStore = articles.filter((el: any) => el.attributes.slug !== article.attributes.slug);

            // rebuild object
            const obj = {
                ...find,
                attributes: { ...find.attributes, isPublished: false },
                createdAt: { ...find.createdAt },
                updatedAt: { ...find.updatedAt }
            };

            // Creates a copy of the store.
            const newArr = [...copyStore];

            // add an element in the idx of a new array.
            newArr.splice(findIndex, 0, obj);

            articleStore({ articles: [...newArr], ...count })
            await Parse.Cloud.run('hiddenArticle', { slug: article.attributes.slug });
            setLoadingUpdate(false);
        } catch (e: any) {
            console.log(e.message);
            setLoadingUpdate(false);
        }
    };


    // Shows the given article.
    async function showArticle(article?: any, setLoadingUpdate?: any) {
        setLoadingUpdate(true);
        const find = articles.find((el: any) => el.attributes.slug === article.attributes.slug);

        const findIndex = articles.findIndex((el: any) => el.attributes.slug === article.attributes.slug);

        const copyStore = articles.filter((el: any) => el.attributes.slug !== article.attributes.slug);

        // rebuild object
        const obj = {
            ...find,
            attributes: { ...find.attributes, isPublished: true },
            createdAt: { ...find.createdAt },
            updatedAt: { ...find.updatedAt }
        };

        // Creates a copy of the store.
        const newArr = [...copyStore];

        // add an element in the idx of a new array.
        newArr.splice(findIndex, 0, obj);

        articleStore({ articles: [...newArr], ...count })

        try {
            await Parse.Cloud.run('showArticle', { slug: article.attributes.slug });
            setLoadingUpdate(false);
        } catch (e: any) {
            console.log(e.message);
            setLoadingUpdate(false);
        }
    };

}