/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from 'react-router'
import HeaderTable from '../components/HeaderTable'
import { useCallback, useLayoutEffect, useState } from 'react';
import { useJobsManagement } from '../store/actions/jobsManagement';
import { useRecoilValue } from 'recoil';
import { jobsStore } from '../store/atoms/jobsStore';
import moment from 'moment';

const ViewJobs = () => {
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    // Use a constant navigator.
    const navigate = useNavigate();

    // Const useUsersManagementAction
    const useJobs = useJobsManagement();

    // Returns all users.
    const returnAllJobs = useCallback(async () => {
        return await useJobs.getAllJobs();
    }, [useJobs]);

    // Wait for a hidden job to be used.
    const handleHiddenJob = useCallback(async (selectedJob: any, setLoadingUpdate: any) => {
        await useJobs.hiddenJob(selectedJob, setLoadingUpdate);
    }, [useJobs]);

    // Wait for a hidden job to be used.
    const handleShowJob = useCallback(async (selectedJob: any, setLoadingUpdate: any) => {
        await useJobs.showJob(selectedJob, setLoadingUpdate);
    }, [useJobs]);

    // Deletes the account of the current user.
    const handleSubmitDeleteJob = useCallback((job: any) => {
        useJobs.deleteJob(job);
    }, [useJobs])

    // Returns a list of all users.
    useLayoutEffect(() => {
        returnAllJobs();
    }, []);

    // Use the users management store.
    const { jobs } = useRecoilValue(jobsStore);
    return (
        <>
            <div>
                <HeaderTable
                    titlePage='Jobs Managements'
                    description={`Any action you take will have consequences, so be careful. ${loadingUpdate ? '| in progress' : ''}`}
                    showInputSearch={false}
                    showBtnAction={true}
                    onPress={() => navigate('/jobs/management/add/job')}
                    titleBtnAction={'Add New Job'}
                />
                <div className="bg-white">
                    <div className="overflow-x-auto border-x border-t">
                        <table className="table-auto w-full">
                            <thead className="border-b">
                                <tr className="bg-gray-100">
                                    <th className="text-left p-4 font-medium">
                                        Title
                                    </th>
                                    <th className="text-left p-4 font-medium">
                                        Creator
                                    </th>
                                    <th className="text-left p-4 font-medium">
                                        CreatedAt
                                    </th>
                                    <th className="text-left p-4 font-medium">
                                        Status
                                    </th>
                                    <th className="text-left p-4 font-medium">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {jobs && jobs.map((job: any) =>
                                    <tr key={Math.random()} className="border-b hover:bg-gray-50">
                                        <td className="p-4 self-center" onCut={() => handleSubmitDeleteJob(job)} >
                                            {job.attributes.title}
                                        </td>
                                        <td className="p-4 self-center">
                                            {job.attributes.creator.attributes.username}
                                        </td>
                                        <td className="p-4 self-center">
                                            {moment(job.attributes.createdAt).fromNow()}
                                        </td>
                                        <td className="p-4 self-center">
                                            {job.attributes.isPublished ?
                                                <span className="bg-green-100 text-green-800 text-xs font-semibold px-5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">Show</span>
                                                :
                                                <span className="bg-red-100 text-red-800 text-xs font-semibold px-4 py-0.5 rounded dark:bg-red-200 dark:text-red-900">Hidden</span>
                                            }
                                        </td>
                                        <td className="p-4 self-center row flex">
                                            {job.attributes.isPublished ?
                                                <button onClick={() => handleHiddenJob(job, setLoadingUpdate)} className='disabled:bg-slate-400 mx-1 group relative flex bg-red-500 p-1 text-white justify-center rounded-md w-20'>Hidden </button>
                                                :
                                                <button onClick={() => handleShowJob(job, setLoadingUpdate)} className='disabled:bg-slate-400 mx-1 group relative flex bg-green-500 p-1 text-white justify-center rounded-md w-20'>Show</button>
                                            }
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
        </>
    )
}

export default ViewJobs