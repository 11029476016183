/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useCallback, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router'
import { useRecoilValue } from 'recoil';
import HeaderTable from '../components/HeaderTable'
import { useArticlesManagement } from '../store/actions/articlesManagement';
import { articlesStore } from '../store/atoms/articlesStore';

const ViewArticle = () => {
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    // Use a constant navigator.
    const navigate = useNavigate();

    // Set the const useArticles constant.
    const useArticles = useArticlesManagement();

    // Gets the article from the article store.
    const { articles } = useRecoilValue(articlesStore);

    // Get the list of articles to use.
    const returnArticles = useCallback(async () => {
        await useArticles.getArticles();
    }, [articles]);

    // Handles hidden articles.
    const handleHiddenArticle = useCallback(async (article?: any) => {
        await useArticles.hiddenArticle(article, setLoadingUpdate);
    }, [articles]);

    // Shows an article.
    const handleShowArticle = useCallback(async (article?: any) => {
        await useArticles.showArticle(article, setLoadingUpdate);
    }, [articles]);

    // Delete an article.
    const handleDeleteArticle = useCallback(async (article: any) => {
        try {
            await useArticles.deleteThisArticle(article);
        } catch (e: any) {
            console.log(e.message);
        }
    }, [articles]);

    // Returns an array of articles.
    useLayoutEffect(() => {
        returnArticles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <HeaderTable
                titlePage='Articles managements'
                description={`Here you can view and manage existing articles and write new articles. ${loadingUpdate ? '| Registration in progress' : ''}`}
                showInputSearch={false}
                showBtnAction={true}
                titleBtnAction="New Article"
                onPress={(e) => navigate('/blog/management/write/article')}
            />
            <div className="bg-white">
                <div className="overflow-x-auto border-x border-t">
                    <table className="table-auto w-full">
                        <thead className="border-b">
                            <tr className="bg-gray-100">
                                <th className="text-left p-4 font-medium">
                                    Title
                                </th>
                                <th className="text-left p-4 font-medium">
                                    Slug
                                </th>
                                <th className="text-left p-4 font-medium">
                                    Creator
                                </th>
                                <th className="text-left p-4 font-medium">
                                    CreatedAt
                                </th>
                                <th className="text-left p-4 font-medium">
                                    Status
                                </th>
                                <th className="text-left p-4 font-medium">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {articles && articles.map((article: any) =>
                                <tr key={article.id} className="border-b hover:bg-gray-50">
                                    <td className="p-4" onCut={() => handleDeleteArticle(article)}>
                                        {article.attributes.title}
                                    </td>
                                    <td className="p-4">
                                        {article.attributes.slug}
                                    </td>
                                    <td className="p-4">
                                        {article.attributes.creator.attributes.username}
                                    </td>
                                    <td className="p-4">
                                        {moment(article.attributes.createdAt).fromNow()}
                                    </td>
                                    <td className="p-4">
                                        {article.attributes.isPublished ?
                                            <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 py-0.5 rounded dark:bg-green-200 dark:text-green-900 px-4">Show</span>
                                            :
                                            <span className="bg-red-100 text-red-800 text-xs font-semibold mr-2  py-0.5 rounded dark:bg-red-200 dark:text-red-900 pl-[11px] pr-[11px]">Hidden</span>
                                        }
                                    </td>
                                    <td className="p-4 row flex">
                                        {article.attributes.isPublished ?
                                            <button onClick={() => handleHiddenArticle(article)} className='mx-1 group relative flex bg-red-500 p-1 text-white justify-center rounded-md px-2'>Hidden</button>
                                            :
                                            <button onClick={() => handleShowArticle(article)} className='mx-1 group relative flex bg-green-600 p-1 text-white justify-center rounded-md px-3.5'>Show</button>
                                        }
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div >
    )
}

export default ViewArticle