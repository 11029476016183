/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useLayoutEffect, useState } from 'react'
import HeaderTable from '../components/HeaderTable'
import { useNavigate } from 'react-router';
import moment from 'moment';
import { useCollectionAction } from '../store/actions/collectionAction';

function CollectionNameManagement() {

    const [collectionName, setCollectionName]: any = useState();

    // Const useUsersManagementAction
    const collectionAction = useCollectionAction()

    // Use a constant navigator.
    const navigate = useNavigate();

    // Returns all users.
    const getCName = useCallback(async () => {
        const res =  await collectionAction.getAllCollectionName();
        setCollectionName(res);
    }, [collectionAction]);

    // Returns a list of all users.
    useLayoutEffect(() => {
        getCName();
    }, []);

    return (
        <div>
            <HeaderTable
                titlePage='Collection Name Managements'
                description={`Any action you take will have consequences, so be careful.`}
                showInputSearch={false}
                showBtnAction={true}
                titleBtnAction='Add New Collection Name'
                onPress={() => navigate('/collectionName/management/add/cn')}
            />
            <div className="bg-white">
                <div className="overflow-x-auto border-x border-t">
                    <table className="table-auto w-full">
                        <thead className="border-b">
                            <tr className="bg-gray-100">
                                <th className="text-left p-4 font-medium">
                                    Name collection name
                                </th>
                                <th className="text-left p-4 w-[32rem] font-medium">
                                    Details
                                </th>
                                <th className="text-left p-4 font-medium">
                                    CreatedAt
                                </th>
                                <th className="text-left p-4 font-medium">
                                    Update
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {collectionName && collectionName.map((cn: any) =>
                                <tr key={cn.id} className="border-b hover:bg-gray-50">
                                    <td className="p-4 self-center">
                                        {cn.attributes.name}
                                    </td>
                                    <td className="p-4 self-center">
                                        {cn.attributes.description}
                                    </td>
                                    <td className="p-4 self-center">
                                        {moment(cn.createdAt).fromNow()}
                                    </td>
                                    <td className="p-4 self-center">
                                        <button onClick={() => navigate(`/collectionName/management/update/${cn.id}`)} className="bg-red-100 text-red-800 text-xs font-semibold mr-2  py-0.5 rounded dark:bg-blue-200 dark:text-blue-900 pl-[11px] pr-[11px] truncate w-28">Update</button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default CollectionNameManagement