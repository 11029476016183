import { useRecoilValue, useSetRecoilState } from "recoil";
import Parse from "parse";
import { collectionStore } from "../atoms/collectionStore";
import { toast } from "react-toastify";

interface interCollectionType {
    getAllCollections(): Promise<any>;
    setCollection(e: any): Promise<any>;
    setPurchasable(e: any, s: any): Promise<any>;
    deleteCollection(e: any): Promise<any>;
    getAllCollectionName(): Promise<any>;
    updateCollection(e: any): Promise<any>;
    setNewCollectionName(e: any, s: any): Promise<any>;
    updateCollectionName(e: any, s: any, f: any): Promise<any>;
    getCollectionNameByUid(e: any, s: any): Promise<any>;
}

export function useCollectionAction(): interCollectionType {
    const setCollectionStore = useSetRecoilState(collectionStore);
    const { collection } = useRecoilValue(collectionStore);
    return {
        getAllCollections,
        setCollection,
        setPurchasable,
        deleteCollection,
        getAllCollectionName,
        updateCollection,
        setNewCollectionName,
        updateCollectionName,
        getCollectionNameByUid
    };

    async function getAllCollections(): Promise<any> {
        //..
        try {
            const result = await Parse.Cloud.run('getAllCollection');
            setCollectionStore({ collection: result })
        } catch (e: any) {
            toast.error(e.message);
        }
    }

    async function setCollection(formCollection: any): Promise<any> {
        // stringify formCollection for parse
        const formSTR = JSON.stringify(formCollection);
        try {
            const res = await Parse.Cloud.run('setNewCollection', { 'formCollection': formSTR });
            if (res.code) {
                toast.success(res.message);
                return;
            }
            toast.error(res.message);
        } catch (e: any) {
            toast.error(e.message);
        }
    }

    async function setPurchasable(product: any, setLoadingReq: any): Promise<any> {
        //..
        setLoadingReq(true);
        const find = collection.find((el: any) => el.id === product.id);

        const findIndex = collection.findIndex((el: any) => el.id === product.id);

        const copyStore = collection.filter((el: any) => el.id !== product.id);

        // rebuild object
        const obj = {
            ...find,
            attributes: { ...find.attributes, isPublished: !find.attributes.isPublished },
            createdAt: { ...find.createdAt },
            updatedAt: { ...find.updatedAt }
        };

        // Creates a copy of the store.
        const newArr = [...copyStore];

        // add an element in the idx of a new array.
        newArr.splice(findIndex, 0, obj);

        setCollectionStore({ collection: newArr });

        try {
            await Parse.Cloud.run('toggleVisibilityCollection', { uuid: find.id, isPub: !find.attributes.isPublished });
            toast.success('The modification has been taken into account.');
            setLoadingReq(false);
        } catch (e: any) {
            toast.error(e.message);
            setLoadingReq(false);
        }
    }


    async function deleteCollection(product: any): Promise<any> {

        // copy collection store
        const copyCollecion = [...collection];

        // delete this object in  an array
        const copyStore = collection.filter((el: any) => el.id !== product.id);

        // Creates a copy of the store.
        const newArr = [...copyStore];

        // update store with  new information
        setCollectionStore({ collection: newArr });

        try {
            // update cloud code 
            const result = await Parse.Cloud.run('deleteCollection', { 'uuid': product.id });
            if (result.code) {
                toast.success(result.message);
                return;
            }
            toast.error(result.message);

            // update store with  new information
            setCollectionStore({ collection: copyCollecion });
        } catch (e: any) {
            toast.success(e.message);
        }
    }


    async function getAllCollectionName(): Promise<any> {

        try {
            const res = await Parse.Cloud.run('getAllCollectionName');
            return res;
        } catch (e: any) {
            toast.success(e.message);
        }
    }

    async function updateCollection(formCollection: any): Promise<any> {
        // stringify formCollection for parse
        const formSTR = JSON.stringify(formCollection);
        try {
            const result = await Parse.Cloud.run('updateCollection', { 'formCollection': formSTR });
            if (result.code) {
                toast.success(result.message);
                return;
            }
            toast.error(result.message);
        } catch (e: any) {
            toast.error(e.message);
        }
    }


    async function setNewCollectionName(formCN: any, setLoadingData: any): Promise<any> {
        setLoadingData(true)
        // stringify formCollection for parse
        try {
            const result = await Parse.Cloud.run('setNewCollectionName', { 'formCollectionName': formCN });
            if (result.code) {
                setLoadingData(false)
                toast.success(result.message);
                return;
            }
            toast.error(result.message);
            setLoadingData(false)
        } catch (e: any) {
            toast.error(e.message);
            setLoadingData(false)
        }
    }



    async function getCollectionNameByUid(id: any, setFormCN: any): Promise<any> {
        // stringify formCollection for parse
        try {
            const result = await Parse.Cloud.run('getCollectionNameById', { id });
            setFormCN({ name: result[0].attributes.name, SD: result[0].attributes.description, ISP: result[0].attributes.isPublished })
        } catch (e: any) {
            toast.error(e.message);
        }
    }




    async function updateCollectionName(id: any, formCN: any, setLoadingData: any): Promise<any> {
        // stringify formCollection for parse
        setLoadingData(true)
        try {
            const result = await Parse.Cloud.run('updateCollectionName', { id, formCN });
            if (result.code) {
                setLoadingData(false)
                toast.success(result.message);
                return;
            }
            toast.error(result.message);
            setLoadingData(false)
        } catch (e: any) {
            toast.error(e.message);
            setLoadingData(false)
        }
    }




}