import Parse from "parse";

export function parseInit() {
    // Your Parse initialization configuration goes here
    const PARSE_APPLICATION_ID = 'PQ4eFYpo4l2h5PPUEC4BAdRMuEgJwWBDv0E6e9pG';
    const PARSE_HOST_URL = 'https://nuxi.b4a.io';
    const PARSE_JAVASCRIPT_KEY = 'KsHOBw7Ix3z6kUJheplNmNzMQfcoAPJ49CypgPvb';
    Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
    Parse.serverURL = PARSE_HOST_URL;
    return Parse;
}
