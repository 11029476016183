import { useSetRecoilState } from "recoil";
import Parse from "parse";
import { currentCategorys } from "../atoms/categoryStore";
import { toast } from "react-toastify";

interface interCategoryType {
    getAllCategory(): Promise<any>;
    setNewCategory(e: any, s: any): Promise<any>;
    getCategoryById(e: any, s: any): Promise<any>;
    updateCategory(e: any, s: any, d: any): Promise<any>;
}

export function useCategoryAction(): interCategoryType {
    const setCollectionStore = useSetRecoilState(currentCategorys);
    return {
        getAllCategory,
        setNewCategory,
        getCategoryById,
        updateCategory
    };

    async function getAllCategory(): Promise<any> {
        try {
            const result = await Parse.Cloud.run('getAllCategorys');
            setCollectionStore({ categorys: result })
            return result;
        } catch (e: any) {
            console.log(e.message);
        }
    }

    async function setNewCategory(formCategory: any, setLoadingData: any): Promise<any> {
        setLoadingData(true);
        try {
            const result = await Parse.Cloud.run('setNewCategory', { formCategory });
            setLoadingData(false);
            toast.success('Good Job, Data saved');
            return result;
        } catch (e: any) {
            console.log(e.message);
            setLoadingData(false);
            toast.error(e.message);
        }
    }

    async function getCategoryById(id: any, setFormCategory: any): Promise<any> {
        try {
            const result = await Parse.Cloud.run('getCategoryById', { id });
            setFormCategory({ name: result[0].attributes.name, SD: result[0].attributes.description })
        } catch (e: any) {
            toast.error(e.message);
        }
    }

    async function updateCategory(id: any, formCategory: any, setLoadingData: any): Promise<any> {
        setLoadingData(true);
        try {
            await Parse.Cloud.run('updateCategory', { id, formCategory });
            toast.success('Good job, Data saved with success');
            setLoadingData(false);
        } catch (e: any) {
            toast.error(e.message);
            setLoadingData(false);
        }
    }


}