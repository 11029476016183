import { useSetRecoilState } from 'recoil';
import { currentUser } from '../atoms/currentUser';
import { toast } from 'react-toastify';
import Parse from "parse";

//REVIEW - tomorow review and update this file

interface interUseUserActions {
    login(email: string, password: string, navigate: any, setFormLogin: any): Promise<interLogin>;
    session(): Promise<any>;
    logout(setLoadingLogout: any): Promise<any>;
}

interface interLogin {
    email: string;
    password: string;
}

export function useUserActions(): interUseUserActions {
    const setAuth = useSetRecoilState(currentUser);
    return {
        login,
        session,
        logout,
    };

    /* -------------------------------------------------------------------------- */
    /*                       // User Login - [POST - PARSE]                       */
    /*                    //TODO: Update system showing errors                    */
    /* -------------------------------------------------------------------------- */
    async function login(username: string, password: string, navigate: any, setFormLogin: any): Promise<any> {
        setFormLogin({ loading: true });
        setTimeout(async () => {
            try {
                await Parse.User.logOut();
                await Parse.Cloud.run('updateUserInAdminPlatform', { username, inAdminPlatform: true });
            } catch (e: any) {
                console.log(e.message)
                return false;
            }
            await Parse.User.logIn(username, password)
                // Save data and connect to db
                .then(async (loggedInUser: any) => {
                    setFormLogin({ loading: true });
                    navigate('/');
                    setAuth({ isAuth: true, attrs: loggedInUser.attributes, uuid: loggedInUser.id });
                    toast('You are connected now');
                })
                // Render And show error
                .catch((error: any) => {
                    setFormLogin({ loading: false });
                    setAuth({ isAuth: false, attrs: undefined, uuid: undefined });
                    toast(error.message);
                });
        }, 1000);
    }

    /* -------------------------------------------------------------------------- */
    /*                      // User Session - [POST - PARSE]                      */
    /*                    //TODO: Update system showing errors                    */
    /* -------------------------------------------------------------------------- */
    async function session(): Promise<any> {
        setTimeout(async () => {
            try {
                // Check in Storage if i have data
                const currentUser = await Parse.User.currentAsync();
                const currentSession = await Parse.Session.current();

                // Save Data in UserStore
                if (currentSession && currentUser !== null) {
                    setAuth({ isAuth: true, attrs: currentUser, uuid: currentUser.id });
                    return;
                }

                setAuth({ isAuth: false, attrs: undefined, uuid: undefined });

                return;
            } catch (error: any) {
                // Render message error
                setAuth({ isAuth: false, attrs: undefined, uuid: undefined });
            }
        }, 1000);
    }

    /* -------------------------------------------------------------------------- */
    /*                       // User Logout - [POST - PARSE]                      */
    /*                    //TODO: Update system showing errors                    */
    /* -------------------------------------------------------------------------- */
    async function logout(setLoadingLogout: any): Promise<any> {
        setLoadingLogout(true);
        setTimeout(async () => {
            await Parse.User.logOut()
                .then(async () => {
                    // Navigation dispatch calls a navigation action, and popToTop will take
                    // the user back to the very first screen of the stack
                    setAuth({ isAuth: false, attrs: undefined, uuid: undefined });
                    setLoadingLogout(false);
                    return true;
                })
                .catch((error: any) => {
                    console.log(error.message);
                    setLoadingLogout(false);
                    setAuth({ isAuth: false, attrs: undefined, uuid: undefined });
                    return false;
                });
        }, 1000);
    }
}