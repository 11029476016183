import { useSetRecoilState } from "recoil";
import { candidateManagementStore } from "../atoms/candidateStore";
import Parse from "parse";

interface interCandidateActionType {
    getCandidate(): Promise<any>;
}

export function useCandidateAction(): interCandidateActionType {
    const setCandidate = useSetRecoilState(candidateManagementStore);
    return {
        getCandidate,
    };

    async function getCandidate(): Promise<any> {
        //..
        try {
            const result = await Parse.Cloud.run('getAllCandidate');
            setCandidate({ candidate: result, counts: result.length })
        } catch (e: any) {
            console.log(e.message);
        }
    }


}