import { Disclosure, Menu } from '@headlessui/react'
import { useCallback, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useUserActions } from '../store/actions/currentUserAction'
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { navigation } from '../data/navigation'

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default function TheNavbar() {
    // Sets the logout and loading logout state.
    const [loadingLogout, setLoadingLogout] = useState(false);

    // Set useUserActions constant to use user actions.
    const useUserAction = useUserActions();

    // Logs out the user.
    const handleLogout = useCallback(async () => {
        await useUserAction.logout(setLoadingLogout);
    }, [useUserAction]);

    return (
        <Disclosure as="nav" className="bg-gray-800">
            <>
                <div className="mx-auto max-w-[95.5%]">
                    <div className="relative flex h-16 items-center justify-between">
                        <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                            <div className="flex flex-shrink-0 items-center">
                                <img
                                    className="hidden h-8 w-auto lg:block"
                                    src={require('../assets/ev_light.png')}
                                    alt="Your Company"
                                />
                            </div>
                            <div className="hidden sm:ml-6 sm:block">
                                <div className="flex space-x-4">
                                    {navigation.map((item, idx) => (
                                        <Menu as="div" key={idx} className="relative inline-block text-left">
                                            <div>
                                                {item.subMenu !== undefined ?
                                                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-gray-800 text-indigo-300 px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-800 hover:bg-gray-500">
                                                        <svg className=" h-2 w-2 text-indigo-300 self-center" fill="currentColor" viewBox="0 0 8 8">
                                                            <circle cx={4} cy={4} r={3} />
                                                        </svg>
                                                        {item.name}
                                                    </Menu.Button>
                                                    :
                                                    <NavLink
                                                        key={item.name}
                                                        to={item.href}
                                                        className={classNames('inline-flex w-full justify-center gap-x-1.5 rounded-md bg-gray-800 text-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-800 hover:bg-gray-500',
                                                            'px-3 py-2 rounded-md text-sm font-medium'
                                                        )}
                                                    >
                                                        {item.name}
                                                    </NavLink>}
                                            </div>

                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="py-1">
                                                        {item.subMenu?.map((subItem, idx) => (
                                                            <Menu.Item key={idx}>
                                                                {({ active }) => (
                                                                    <NavLink
                                                                        to={subItem.href}
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                            'block px-4 py-2 text-sm'
                                                                        )}
                                                                    >
                                                                        {subItem.name}
                                                                    </NavLink>
                                                                )}
                                                            </Menu.Item>
                                                        ))
                                                        }
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

                            {/* Profile dropdown */}
                            <Menu as="div" className="relative ml-3">
                                <div>
                                    <button
                                        onClick={handleLogout}
                                        className={'bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium'}
                                    >
                                        {loadingLogout ? 'Loading...' : 'Logout'}
                                    </button>
                                </div>
                            </Menu>
                        </div>
                    </div>
                </div>
            </>
        </Disclosure>
    )
}
