import { Editor } from '@tinymce/tinymce-react'
import { useCallback, useMemo, useRef, useState } from 'react'
import HeaderTable from '../components/HeaderTable';
import { useNavigate } from 'react-router';
import { useJobsManagement } from '../store/actions/jobsManagement';

const AddNewJob = () => {
    // Creates a formulary.
    const formulary = useMemo(() => {
        return ({
            title: '',
            slug: '',
            TOC: '', // type of contract
            POW: '', // place of work
            content: '',
            inputTags: '',
            tags: [] as any,
        })
    }, []);

    // Returns a const for the given formulary.
    const [formJob, setFormJob]: any = useState(formulary);
    const [LoadingSave, setLoadingSave]: any = useState(false);

    // Use a constant editorRef.
    const editorRef: any = useRef(null);

    // Sets the const useJobs constant.
    const useJobs = useJobsManagement();

    // Adds tags to the article.
    const handleAddTagsInArray = useCallback((e: any) => {
        if (e.key === 'Enter') {
            const newArr = { ...formJob };
            newArr.tags.unshift(formJob.inputTags.trim());
            setFormJob({ ...newArr, inputTags: '' });
        }
    }, [formJob])

    // Handle change text input.
    const handleChangeTextInput = async (e: any) => {
        if (e.name === 'title') {
            formJob[e.name] = e.value;
            formJob.slug = e.value.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');;
            return;
        }
        formJob[e.name] = e.value;
    }

    // Saves the form article.
    const handleSubmitSaveArticle = useCallback(async () => {
        if (editorRef.current) formJob.content = editorRef.current.getContent();
        try {
            await useJobs.saveJob(formJob, setLoadingSave);
            setFormJob(formulary);
        } catch (e: any) {
            console.log(e.message);
        }
    }, [formJob, editorRef, formulary, useJobs]);

    // Deletes an article.
    const handleDeleteTag = useCallback((tag: any, idx: any) => {
        const newArr = { ...formJob };
        newArr.tags.shift(tag);
        setFormJob({ ...newArr });
    }, [formJob])

    // Use a constant navigator.
    const navigate = useNavigate();
    return (
        <div className="w-full grid h-screen place-items-center">
            <HeaderTable
                titlePage='Add New Job Offer'
                description='When posting a job, make sure the spelling is correct.'
                showInputSearch={false}
                showBtnAction={true}
                titleBtnAction="Return to list jobs"
                onPress={(e) => navigate('/jobs/management')}
            />
            <div className=" justify-end flex w-[50rem]">
                <button disabled className=" disabled:text-white disabled:hover:bg-gray-200 disabled:cursor-not-allowed bg-gray-200 px-3 py-1  mb-2 rounded-lg ml-2 text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300">Save and Publish</button>
                <button onClick={() => handleSubmitSaveArticle()} className="bg-gray-200 px-3 py-1  mb-2 rounded-lg ml-2 text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300">{LoadingSave ? 'In Progress' : 'Just Save'}</button>
            </div>
            <div className="ml-3 w-[50rem] justify-center">
                <label className=" text-gray-400 text-sm">Title of Job</label>
                <input required onChange={(e: any) => handleChangeTextInput(e.target)} className="bg-gray-100 p-3 outline-none mb-2 w-full block " type="text" name="title" id="" placeholder="Title" />
            </div>
            <div className="ml-3 w-[50rem] justify-center">
                <label className=" text-gray-400 text-sm">Place of Work</label>
                <input required onChange={(e: any) => handleChangeTextInput(e.target)} className="bg-gray-100 p-3 outline-none mb-2 w-full block " type="text" name="POW" id="" placeholder="Ifarhounene" />
            </div>
            <div className="ml-3 w-[50rem] justify-center">
                <label className=" text-gray-400 text-sm">Type of contract</label>
                <input required onChange={(e: any) => handleChangeTextInput(e.target)} className="bg-gray-100 p-3 outline-none mb-2 w-full block " type="text" name="TOC" id="" placeholder="CDI OR CDD OR ..." />
            </div>
            <div className="ml-3  w-[50rem]">
                <div className="flex py-7 px-2 my-5 border-gray-400 border-2 rounded-lg">
                    <span id="badge-dismiss-dark" className="inline-flex items-center py-1 px-2 mr-2 text-sm font-medium text-gray-800 bg-gray-100 rounded dark:bg-gray-200 dark:text-gray-800">Your Tags :</span>
                    {formJob.tags && formJob.tags.map((tag: any, idx: any) => (
                        <span key={Math.random()} id="badge-dismiss-dark" className="inline-flex items-center py-1 px-2 mr-2 text-sm font-medium text-gray-800 bg-gray-100 rounded dark:bg-gray-200 dark:text-gray-800">
                            {tag}
                            <button onClick={(e) => handleDeleteTag(tag, idx)} type="button" className="inline-flex items-center p-0.5 ml-2 text-sm text-gray-400 bg-transparent rounded-sm hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-300 dark:hover:text-gray-900" data-dismiss-target="#badge-dismiss-dark" aria-label="Remove">
                                <span className="px-1">☓</span>
                            </button>
                        </span>
                    ))}
                </div>
                <label className=" text-gray-500 text-sm">Add tags in article</label>
                <pre className="py-2 text-gray-400 text-xs">
                    Press &nbsp;
                    <kbd className="px-1 py-0.5 text-xs font-semibold text-gray-300 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-100 dark:text-gray-500 dark:border-gray-300 ">Enter</kbd>
                    &nbsp; to add the tag in the article
                </pre>
                <input value={formJob.inputTags} onKeyUp={(e) => formJob.inputTags.trim() !== '' && handleAddTagsInArray(e)} onChange={(e) => setFormJob({ ...formJob, inputTags: e.target.value })} className="bg-gray-100 p-3 outline-none w-full block mb-10" type="text" placeholder="tags" />
            </div>
            <div className="mx-3  w-[50rem]">
                <label className=" text-gray-400 text-sm">Content of Job</label>
                <Editor
                    apiKey="5q745td1hp1gy5mgpcvjjyx5tywbhzehk8xmg45frm8n6rvw"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    init={{
                        height: 600,
                        menubar: true,
                        plugins:
                            "image link table lists autolink searchreplace directionality visualblocks visualchars media  codesample charmap pagebreak nonbreaking anchor insertdatetime autosave wordcount emoticons",
                        toolbar:
                            "undo redo formatpainter | blocks fontfamily fontsize | bold italic underline forecolor backcolor | link image addcomment showcomments  | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | removeformat",
                        toolbar_sticky: true,
                        icons: "thin",
                        skin: "material-classic",
                        content_style: "material-classic",
                    }}
                />
            </div>
        </div >
    )
}

export default AddNewJob