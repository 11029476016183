/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import HeaderTable from '../components/HeaderTable';
import { useCandidateAction } from '../store/actions/candidateAction';
import { useRecoilValue } from 'recoil';
import { candidateManagementStore } from '../store/atoms/candidateStore';
import moment from 'moment';
import ModalDetails from '../components/condidateManagement/modalDetails';

const CandidateManagement = () => {
    const [open, setOpen] = useState(false);
    const [dataModal, setDataModal] = useState(null);

    const useCandidate = useCandidateAction();

    const getAllCandidate = useCallback(async () => {
        return await useCandidate.getCandidate();
    }, [useCandidate]);

    useLayoutEffect(() => {
        getAllCandidate();
    }, [])

    const { candidate } = useRecoilValue(candidateManagementStore);

    const renderDataInStore = useMemo(() => {
        return candidate ? candidate : [];
    }, [candidate]);

    const handleSetOpenModal = (data: any) => {
        setOpen(true);
        setDataModal(data);
    }

    return (
        <div>
            <HeaderTable
                titlePage='Candidate Managements'
                description={`Any action you take will have consequences, so be careful.`}
                showInputSearch={true}
                showBtnAction={false}
            />
            <div className="bg-white">
                <div className="overflow-x-auto border-x border-t">
                    <table className="table-auto w-full">
                        <thead className="border-b">
                            <tr className="bg-gray-100">
                                <th className="text-left p-4 font-medium">
                                    Apply At
                                </th>
                                <th className="text-left p-4 font-medium">
                                    Name
                                </th>
                                <th className="text-left p-4 font-medium">
                                    First Name
                                </th>
                                <th className="text-left p-4 font-medium">
                                    Phone
                                </th>
                                <th className="text-left p-4 font-medium">
                                    Apply for
                                </th>
                                <th className="text-left p-4 font-medium">
                                    Email
                                </th>
                                <th className="text-left p-4 font-medium">
                                    Cv
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderDataInStore && renderDataInStore.map((el: any) =>
                                <tr key={el.id} className="border-b hover:bg-gray-50">
                                    <td className="p-4 self-center text-sm" >
                                        {moment(el.attributes.createdAt).fromNow()}
                                    </td>
                                    <td className="p-4 self-center text-sm">
                                        {el.attributes.lastName}
                                    </td>
                                    <td className="p-4 self-center text-sm">
                                        {el.attributes.firstName}
                                    </td>
                                    <td className="p-4 self-center text-sm">
                                        {el.attributes.phone}
                                    </td>
                                    <td className="p-4 self-center text-sm">
                                        {el.attributes.job.attributes.title}
                                    </td>
                                    <td className="p-4 self-center text-sm">
                                        {el.attributes.email}
                                    </td>
                                    <td className="p-4 self-center text-sm">
                                        <div className='row flex'>
                                            {el.attributes.cv != undefined ? <a href={el.attributes.cv._url} target="_blank" rel="noreferrer" className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full' download>READ</a> : <p className='bg-gray-400 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded-full'>NULL</p>}
                                            <button onClick={() => handleSetOpenModal(el)} className='bg-blue-500 hover:bg-blue-700 ml-2 text-white font-bold py-2 px-4 rounded-full'>Show more</button>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        {renderDataInStore && open === true && <ModalDetails open={open} setOpen={setOpen} data={dataModal} />}
                    </table>
                </div>
            </div>
        </div >
    )
}

export default CandidateManagement;