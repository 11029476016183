/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useLayoutEffect, useState } from 'react'
import HeaderTable from '../components/HeaderTable'
import { useNavigate, useParams } from 'react-router';
import { useCollectionAction } from '../store/actions/collectionAction';

function UpdateCollectionName() {
    const [loadingData, setLoadingData] = useState(false);
    const [formCN, setFormCN] = useState({
        name: '',
        SD: '',
        ISP: false
    });

    //const useQuery
    const { id } = useParams();

    if (id === undefined) throw new Error("Error, this page not exist");


    // init category action
    const collectionAction = useCollectionAction();

    // handle change text in state
    const handleChangeTextInput = useCallback((e: any) => {
        setFormCN({ ...formCN, [e.name]: e.value })
    }, [formCN]);

    // handle submit data in the cloud
    const handleSubmitData = useCallback(async () => {
        try {
            await collectionAction.updateCollectionName(id, formCN, setLoadingData);
        } catch (e: any) {
            console.log(e.message);
        }
    }, [collectionAction, setLoadingData, id]);


    // handle get data by id
    const handleGetCategoryById = useCallback(async () => {
        try {
            await collectionAction.getCollectionNameByUid(id, setFormCN)
        } catch (e: any) {
            console.log(e.message);
        }
    }, [collectionAction, setLoadingData, id]);

    // Use a constant navigator.
    const navigate = useNavigate();

    // use layout effect
    useLayoutEffect(() => {
        handleGetCategoryById()
    }, []);

    return (
        <div className='w-full grid place-items-center'>
            <HeaderTable
                titlePage='Update category Managements'
                description={`Any action you take will have consequences, so be careful.`}
                showInputSearch={false}
                showBtnAction={true}
                titleBtnAction='Return to collection name'
                onPress={() => navigate('/collectionName/management')}
            />
            <div>
                <div className=" justify-end flex w-[50rem]">
                    {(formCN.name && formCN.SD) && <button onClick={() => handleSubmitData()} className="bg-gray-200 px-3 py-1  mb-2 rounded-lg ml-2 text-black font-semibold tracking-wide cursor-pointer hover:bg-slate-300">{loadingData ? 'In Progress' : 'Just Save'}</button>}
                </div>
                <div className="ml-3 w-[50rem] justify-center">
                    <label className=" text-gray-400 text-sm">Name category</label>
                    <input required onChange={(e: any) => handleChangeTextInput(e.target)} defaultValue={formCN.name} className="bg-gray-100 p-3 outline-none mb-2 w-full block " type="text" name="name" id="" placeholder="name of category" />
                </div>
                <div className="ml-3 w-[50rem] justify-center">
                    <label className=" text-gray-400 text-sm">Small Description</label>
                    <input required onChange={(e: any) => handleChangeTextInput(e.target)} defaultValue={formCN.SD} className="bg-gray-100 p-3 outline-none mb-2 w-full block " type="text" name="SD" id="" placeholder="small description of category" />
                </div>
                <div className="ml-3 w-[50rem] justify-center">
                    <select name="ISP" defaultChecked={formCN.ISP} id="pet-select" onChange={(e: any) => handleChangeTextInput(e.target)} className='w-full border-2 p-3 mt-2'>
                        <option value="true" selected={formCN.ISP === true}>is published</option>
                        <option value="false" selected={formCN.ISP === false}>is not published</option>
                    </select>
                </div>
            </div>
        </div>
    )
}

export default UpdateCollectionName;